var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailsWrap" },
    [
      _c("div", [
        _c("span", { staticClass: "detailsTitle" }, [_vm._v("产品信息")]),
        _c(
          "span",
          {
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.editProduct },
          },
          [_c("a-icon", { attrs: { type: "edit" } }), _vm._v(" 编辑产品信息")],
          1
        ),
        _c("div", { staticClass: "detailsList" }, [
          _c("ul", [
            _c("li", [_vm._v("产品Code：" + _vm._s(_vm.productInfo.code))]),
            _c("li", [
              _vm._v("接入协议：" + _vm._s(_vm.productInfo.accessProtocolName)),
            ]),
            _c("li", [
              _vm._v("设备数量：" + _vm._s(_vm.productInfo.deviceCount)),
            ]),
            _c("li", [
              _vm._v("联网类型：" + _vm._s(_vm.productInfo.networkMode)),
            ]),
            _c("li", [_vm._v("产品描述：" + _vm._s(_vm.productInfo.remark))]),
          ]),
          _c("ul", [
            _c("li", [
              _vm._v(
                "节点类型：" +
                  _vm._s(_vm._f("nodeTypeFilter")(_vm.productInfo.nodeType))
              ),
            ]),
            _c("li", [
              _vm._v("创建时间：" + _vm._s(_vm.productInfo.createTime)),
            ]),
            _c("li", [_vm._v("产品key：" + _vm._s(_vm.productInfo.keyInfo))]),
            _vm.productInfo.accessProtocolName === "MQTT"
              ? _c("li", [
                  _vm._v(
                    " clientid: " + _vm._s(_vm.productInfo.mqttClientId) + " "
                  ),
                ])
              : _vm._e(),
            _c("li", [
              _vm._v(
                "所属省市：" +
                  _vm._s(_vm.productInfo.provinceName) +
                  _vm._s(_vm.productInfo.cityName)
              ),
            ]),
          ]),
          _c("ul", [
            _c("li", [
              _vm._v("产品类型：" + _vm._s(_vm.productInfo.categoryName)),
            ]),
            _c("li", [
              _vm._v("产品名称：" + _vm._s(_vm.productInfo.productName)),
            ]),
            _c("li", [
              _vm._v("产品厂商：" + _vm._s(_vm.productInfo.manufacturer)),
            ]),
            _vm.productInfo.accessProtocolName === "MQTT"
              ? _c("li", [
                  _vm._v(
                    " username: " + _vm._s(_vm.productInfo.mqttUserName) + " "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "card-container" },
          [
            _c(
              "a-tabs",
              { attrs: { type: "card" } },
              [
                _c(
                  "a-tab-pane",
                  { key: "1", attrs: { tab: "设备档案" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "margin-bottom": "12px",
                        },
                      },
                      [
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "220px" },
                            model: {
                              value: _vm.equipStatus,
                              callback: function ($$v) {
                                _vm.equipStatus = $$v
                              },
                              expression: "equipStatus",
                            },
                          },
                          [
                            _c("a-select-option", { attrs: { value: "all" } }, [
                              _vm._v(" 设备状态（全部） "),
                            ]),
                            _c("a-select-option", { attrs: { value: "0" } }, [
                              _vm._v(" 未激活 "),
                            ]),
                            _c("a-select-option", { attrs: { value: "1" } }, [
                              _vm._v(" 在线 "),
                            ]),
                            _c("a-select-option", { attrs: { value: "2" } }, [
                              _vm._v(" 离线 "),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "a-select",
                          {
                            staticStyle: {
                              width: "220px",
                              margin: "0 100px 0 30px",
                            },
                            model: {
                              value: _vm.createType,
                              callback: function ($$v) {
                                _vm.createType = $$v
                              },
                              expression: "createType",
                            },
                          },
                          [
                            _c("a-select-option", { attrs: { value: "all" } }, [
                              _vm._v(" 创建方式（全部） "),
                            ]),
                            _c("a-select-option", { attrs: { value: "0" } }, [
                              _vm._v(" 人工注册 "),
                            ]),
                            _c("a-select-option", { attrs: { value: "1" } }, [
                              _vm._v(" 自动注册 "),
                            ]),
                          ],
                          1
                        ),
                        _c("a-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            placeholder: "请输入设备code",
                            "allow-clear": true,
                          },
                          model: {
                            value: _vm.keyword,
                            callback: function ($$v) {
                              _vm.keyword = $$v
                            },
                            expression: "keyword",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "12px" } },
                      [
                        _c("DateRangePicker", {
                          attrs: {
                            tip: ["请选择最近在线时间段", ""],
                            dates: _vm.nowDates,
                          },
                          on: {
                            chooseDate: _vm.nowDate,
                            dateClear: _vm.onlineDateClear,
                          },
                        }),
                        _c("DateRangePicker", {
                          staticStyle: { "margin-left": "20px" },
                          attrs: {
                            tip: ["请选择最近添加时间段", ""],
                            dates: _vm.nowAddDates,
                          },
                          on: {
                            chooseDate: _vm.nowAddDate,
                            dateClear: _vm.nowDateClear,
                          },
                        }),
                        _c(
                          "a-button",
                          {
                            staticStyle: { margin: "0 20px" },
                            on: { click: _vm.resetDate },
                          },
                          [_vm._v(" 重置 ")]
                        ),
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.searchProduct },
                          },
                          [_vm._v(" 查询 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _c("a-button", { attrs: { type: "primary" } }, [
                          _vm._v(" 导出设备 "),
                        ]),
                      ],
                      1
                    ),
                    _c("a-table", {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: {
                        "row-key": "id",
                        locale: { emptyText: "暂无数据" },
                        columns: _vm.columns,
                        pagination: false,
                        "data-source": _vm.data,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "status",
                          fn: function (text, record) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("statusTypeFilter")(record.status)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "source",
                          fn: function (text, record) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("sourceTypeFilter")(record.source)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "operation",
                          fn: function (text, record) {
                            return [
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "#1890ff" },
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoDetails(record)
                                    },
                                  },
                                },
                                [_vm._v("详情")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("Pagination", {
                      attrs: { "current-page": _vm.page, count: _vm.total },
                      on: {
                        handleChange: _vm.changePage,
                        handleChangeSize: _vm.changeSize,
                      },
                    }),
                  ],
                  1
                ),
                _c("a-tab-pane", { key: "2", attrs: { tab: "物模型管理" } }, [
                  _c("div", { staticClass: "authorizeItem" }, [
                    _c(
                      "p",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { hidden: _vm.setModelFlag },
                      },
                      [
                        _c("a-button", { on: { click: _vm.downloadSDK } }, [
                          _vm._v(" 下载SDK "),
                        ]),
                        _c(
                          "a-button",
                          {
                            staticStyle: { margin: "0 25px" },
                            on: { click: _vm.setModel },
                          },
                          [_vm._v(" 设置物模型 ")]
                        ),
                        _c("a-button", { attrs: { type: "primary" } }, [
                          _vm._v(" 导出物模型 "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isShow,
                            expression: "!isShow",
                          },
                        ],
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass: "setModel",
                            on: { click: _vm.setModel },
                          },
                          [_c("a-icon", { attrs: { type: "plus-circle" } })],
                          1
                        ),
                        _c("li", [_vm._v('点击"设置物模型"，开启物模型编辑')]),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.setModelFlag,
                          expression: "setModelFlag",
                        },
                      ],
                    },
                    [
                      _c(
                        "p",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("a-button", [_vm._v(" 导入物模型 ")]),
                          _c(
                            "a-button",
                            { staticStyle: { margin: "0 25px" } },
                            [_vm._v(" 批量删除 ")]
                          ),
                          _c("a-button", [_vm._v(" 添加系统功能点 ")]),
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "25px" },
                              on: {
                                click: function ($event) {
                                  _vm.isVisible = true
                                },
                              },
                            },
                            [_vm._v(" 添加自定义功能点 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-right": "25px" },
                              on: { click: _vm.onSaveList },
                            },
                            [_vm._v(" 保存 ")]
                          ),
                          _c("a-button", { on: { click: _vm.goBack } }, [
                            _vm._v(" 返回 "),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShow,
                          expression: "isShow",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "detailsChoose" }, [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c(
                              "a-select",
                              {
                                staticStyle: { width: "220px" },
                                attrs: { "default-value": "all" },
                                on: { change: _vm.funType },
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "all" } },
                                  [_vm._v(" 功能类型（全部） ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "properties" } },
                                  [_vm._v(" 属性 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "events" } },
                                  [_vm._v(" 事件 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  {
                                    attrs: { value: "services", disabled: "" },
                                  },
                                  [_vm._v(" 服务 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-select",
                              {
                                staticStyle: {
                                  width: "220px",
                                  margin: "0 30px 0",
                                },
                                attrs: { "default-value": "all" },
                                on: { change: _vm.funCata },
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "all" } },
                                  [_vm._v(" 功能类别（全部） ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "y", disabled: "" } },
                                  [_vm._v(" 系统 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "u" } },
                                  [_vm._v(" 自定义 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "st", disabled: "" } },
                                  [_vm._v(" 标准 ")]
                                ),
                              ],
                              1
                            ),
                            _c("a-input-search", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                placeholder: "请输入功能名称搜索",
                                "enter-button": "",
                              },
                              on: { search: _vm.onKeySearch },
                              model: {
                                value: _vm.keywords,
                                callback: function ($$v) {
                                  _vm.keywords = $$v
                                },
                                expression: "keywords",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("a-table", {
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: {
                          "row-key": "id",
                          locale: { emptyText: "暂无数据" },
                          columns: _vm.setModelColumns,
                          pagination: false,
                          "data-source": _vm.setModelData,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "type",
                            fn: function (text, record) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("functionTypeFilter")(record.type)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "fnType",
                            fn: function (text, record) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("functionCataFilter")(
                                        record.fnType
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "dataType",
                            fn: function (text, record) {
                              return [
                                record.type === "events"
                                  ? _c("span", [_vm._v("-")])
                                  : _c("span", [
                                      _vm._v(_vm._s(record.dataType)),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "define",
                            fn: function (text, record) {
                              return [
                                record.type === "events"
                                  ? _c("span", [_vm._v("-")])
                                  : _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.defineFilter(record)
                                        ),
                                      },
                                    }),
                              ]
                            },
                          },
                          {
                            key: "readType",
                            fn: function (text, record) {
                              return [
                                record.type === "events"
                                  ? _c("span", [_vm._v("-")])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("rwTypeFilter")(
                                            record.readType
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "operation",
                            fn: function (text, record, index) {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      color: "#1890ff",
                                      "margin-right": "30px",
                                    },
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showInfo(record)
                                      },
                                    },
                                  },
                                  [_vm._v("查看")]
                                ),
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isSetModel,
                                        expression: "isSetModel",
                                      },
                                    ],
                                    staticStyle: { color: "#1890ff" },
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDelete(index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "a-modal",
        {
          staticClass: "addProduct",
          attrs: {
            width: 800,
            "cancel-text": "取消",
            "ok-text": "确认",
            title: "编辑产品信息",
            visible: _vm.visible,
          },
          on: {
            ok: _vm.onSubmit,
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { ref: "name", attrs: { label: "产品名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "city", attrs: { label: "所属省市", prop: "city" } },
                [
                  _c("CityChoice", {
                    attrs: { "prov-val": _vm.provVal, "city-val": _vm.cityVal },
                    on: { getProv: _vm.getProvInfo, getCity: _vm.getCityInfo },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "联网方式", prop: "delivery" } },
                [
                  _c(
                    "a-checkbox-group",
                    {
                      model: {
                        value: _vm.form.delivery,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "delivery", $$v)
                        },
                        expression: "form.delivery",
                      },
                    },
                    [
                      _c("a-checkbox", { attrs: { value: "蜂窝" } }, [
                        _vm._v(" 蜂窝 "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "以太" } }, [
                        _vm._v(" 以太 "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "NB" } }, [
                        _vm._v(" NB "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "WIFI" } }, [
                        _vm._v(" WIFI "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "其他" } }, [
                        _vm._v(" 其他 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: { label: "产品厂商", prop: "firm" },
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.form.firm,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "firm", $$v)
                      },
                      expression: "form.firm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: { label: "产品型号", prop: "model" },
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.form.model,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "model", $$v)
                      },
                      expression: "form.model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: { label: "产品描述", prop: "desc" },
                },
                [
                  _c("a-textarea", {
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Dialog", {
        attrs: { "is-dialog-show": _vm.isVisible, "data-list": _vm.dataList },
        on: {
          cancel: function ($event) {
            _vm.isVisible = false
          },
          onSave: _vm.onSave,
        },
      }),
      _c("DetailsDialog", {
        attrs: {
          "is-dialog-show": _vm.isShowDialog,
          "model-form": _vm.objInfo,
        },
        on: {
          closeDialog: function ($event) {
            _vm.isShowDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }