<template>
    <div>
        <a-modal
            :width="800"
            cancel-text="取消"
            ok-text="保存"
            title="添加自定义功能点"
            :visible="isDialogShow"
            @ok="onSave"
            v-on="$listeners">
            <a-form-model
                ref="modelRuleForm"
                :model="modelForm"
                :rules="modelRules">
                <a-form-model-item ref="name" label="功能名称" prop="name">
                    <a-input
                        v-model="modelForm.name"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item ref="sign" label="标识符" prop="sign">
                    <a-input
                        v-model="modelForm.sign"
                        placeholder="最长不超过32位，英文，数字及特殊字符，必须以英文开头" />
                </a-form-model-item>
                <a-form-model-item ref="type" label="功能类型" prop="type">
                    <a-select v-model="modelForm.type" @change="chooseFnType">
                        <a-select-option value="properties">
                            属性类型
                        </a-select-option>
                        <a-select-option value="events">
                            事件类型
                        </a-select-option>
                        <a-select-option value="services" disabled>
                            服务类型
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item v-show="indexs !== 7" ref="dataType" label="数据类型" prop="dataType">
                    <a-select v-model="modelForm.dataType" @change="chooseDataType">
                        <a-select-option value="int32(整数型)">
                            int32(整数型)
                        </a-select-option>
                        <a-select-option value="int64(长整数型)">
                            int64(长整数型)
                        </a-select-option>
                        <a-select-option value="float(单精度浮点型)">
                            float(单精度浮点型)
                        </a-select-option>
                        <a-select-option value="double(双精度浮点型)">
                            double(双精度浮点型)
                        </a-select-option>
                        <a-select-option value="enum(枚举)">
                            enum(枚举)
                        </a-select-option>
                        <a-select-option value="bool(布尔)">
                            bool(布尔)
                        </a-select-option>
                        <a-select-option value="string(字符串)">
                            string(字符串)
                        </a-select-option>
                        <a-select-option value="date(时间)">
                            date(时间)
                        </a-select-option>
                        <a-select-option value="array(数组)">
                            array(数组)
                        </a-select-option>
                        <a-select-option value="file(文件)">
                            file(文件)
                        </a-select-option>
                        <a-select-option value="password(密码)">
                            password(密码)
                        </a-select-option>
                        <a-select-option value="geopoint(地理位置)">
                            geopoint(地理位置)
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <!-- <a-form-model-item v-show="indexs === 7" ref="eventType" label="事件类型" prop="eventType">
                    <a-select v-model="modelForm.eventType">
                        <a-select-option value="info">
                            信息
                        </a-select-option>
                        <a-select-option value="alert">
                            告警
                        </a-select-option>
                        <a-select-option value="error">
                            故障
                        </a-select-option>
                    </a-select>
                </a-form-model-item> -->
                <div v-show="indexs === 1">
                    <a-form-model-item ref="intMin" style="display: inline-block; width: 27%" label="定义取值范围" prop="intMin">
                        <a-input
                            v-model="modelForm.intMin"
                            placeholder="最小值" />
                    </a-form-model-item>
                    <a-form-model-item class="hiddenLabel" style="display: inline-block; margin: 0 2%" label="-">
                        <span>——</span>
                    </a-form-model-item>
                    <a-form-model-item ref="intMax" class="hiddenLabel" style="display: inline-block; width: 27%" label="定义取值范围" prop="intMax">
                        <a-input
                            v-model="modelForm.intMax"
                            placeholder="最大值" />
                    </a-form-model-item>
                    <a-form-model-item ref="length" style="display: inline-block; width: 47%" label="步长" prop="length">
                        <a-input
                            v-model="modelForm.length"
                            placeholder="请输入数据精度" />
                    </a-form-model-item>
                    <a-form-model-item ref="unit" style="display: inline-block; width: 47%; margin-left: 6%" label="单位" prop="unit">
                        <a-input
                            v-model="modelForm.unit"
                            placeholder="请自定义数据单位" />
                    </a-form-model-item>
                    <a-form-model-item ref="readType" label="读写类型" prop="readType">
                        <a-select v-model="modelForm.readType">
                            <a-select-option value="rw">
                                读写
                            </a-select-option>
                            <a-select-option value="r">
                                只读
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div v-show="indexs === 2">
                    <p>
                        <span>枚举项</span>
                        <span style="margin-left: 100px; color: #999">最多100项</span>
                    </p>
                    <p>
                        <span>参数值</span>
                        <span style="margin-left: 27%">参数描述</span>
                    </p>
                    <a-form-model-item
                        v-for="(domain, index) in modelForm.domains"
                        :key="domain.key"
                        :prop="'domains.' + index + '.value'"
                        :rules="{
                            required: true,
                            message: '参数值和描述不能为空!',
                            trigger: 'blur',
                        }">
                        <a-input
                            v-model="domain.value"
                            placeholder="请输入参数值"
                            style="width: 30%; display: inline-block" />
                        <a-input
                            v-model="domain.desc"
                            placeholder="请输入参数描述"
                            style="width: 55%; display: inline-block; margin: 0 10px 0 2%" />
                        <a-icon
                            v-if="modelForm.domains.length > 1"
                            class="dynamic-delete-button"
                            type="minus-circle-o"
                            :disabled="modelForm.domains.length === 1"
                            @click="removeDomain(domain)" />
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-button type="dashed" style="width: 60%" @click="addDomain">
                            <a-icon type="plus" /> 添加枚举项
                        </a-button>
                    </a-form-model-item>
                </div>
                <div v-show="indexs === 3">
                    <a-form-model-item ref="boolT" label="布尔值" prop="boolT">
                        true - <a-input
                            v-model="modelForm.boolT"
                            style="display: inline-block; width: 80%; margin-left: 12px"
                            placeholder="1-20位，中文、英文、数字及特殊字符_-，必须以中文、英文或数字开头" />
                    </a-form-model-item>
                    <a-form-model-item ref="boolF" label="" prop="boolF">
                        false - <a-input
                            v-model="modelForm.boolF"
                            style="display: inline-block; width: 80%; margin-left: 12px"
                            placeholder="1-20位，中文、英文、数字及特殊字符_-，必须以中文、英文或数字开头" />
                    </a-form-model-item>
                    <a-form-model-item ref="readType" label="读写类型" prop="readType">
                        <a-select v-model="modelForm.readType">
                            <a-select-option value="rw">
                                读写
                            </a-select-option>
                            <a-select-option value="r">
                                只读
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div v-show="indexs === 4">
                    <a-form-model-item ref="dataLen" label="数据长度" prop="dataLen">
                        <a-input
                            v-model="modelForm.dataLen"
                            style="display: inline-block; width: 80%; margin-right: 12px"
                            placeholder="整数最大256，单位：字符" /> 字符
                    </a-form-model-item>
                    <a-form-model-item ref="readType" label="读写类型" prop="readType">
                        <a-select v-model="modelForm.readType">
                            <a-select-option value="rw">
                                读写
                            </a-select-option>
                            <a-select-option value="r">
                                只读
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div v-show="indexs === 5">
                    <a-form-model-item label="时间格式">
                        <a-input
                            disabled
                            placeholder="整数类型Int64的UTC时间戳(毫秒)" />
                    </a-form-model-item>
                    <a-form-model-item ref="readType" label="读写类型" prop="readType">
                        <a-select v-model="modelForm.readType">
                            <a-select-option value="rw">
                                读写
                            </a-select-option>
                            <a-select-option value="r">
                                只读
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div v-show="indexs === 6">
                    <a-form-model-item ref="arrayDataType" label="元素类型" prop="arrayDataType">
                        <a-select v-model="modelForm.arrayDataType" @change="chooseArrayType">
                            <a-select-option value="int32">
                                int32(整数型)
                            </a-select-option>
                            <a-select-option value="int64">
                                int64(长整数型)
                            </a-select-option>
                            <a-select-option value="float">
                                float(单精度浮点型)
                            </a-select-option>
                            <a-select-option value="double">
                                double(双精度浮点型)
                            </a-select-option>
                            <a-select-option value="string">
                                string(字符串)
                            </a-select-option>
                            <a-select-option value="date">
                                date(时间)
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="len" label="元素个数" prop="len">
                        <a-input
                            v-model="modelForm.len"
                            placeholder="元素最大支持10个" />
                    </a-form-model-item>
                    <div v-show="arrayCon === 1">
                        <a-form-model-item ref="arrayIntMin" style="display: inline-block; width: 27%" label="定义取值范围" prop="arrayIntMin">
                            <a-input
                                v-model="modelForm.arrayIntMin"
                                placeholder="最小值" />
                        </a-form-model-item>
                        <a-form-model-item class="hiddenLabel" style="display: inline-block; margin: 0 2%" label="-">
                            <span>——</span>
                        </a-form-model-item>
                        <a-form-model-item ref="arrayIntMax" class="hiddenLabel" style="display: inline-block; width: 27%" label="定义取值范围" prop="arrayIntMax">
                            <a-input
                                v-model="modelForm.arrayIntMax"
                                placeholder="最大值" />
                        </a-form-model-item>
                        <a-form-model-item ref="length" label="步长" prop="length">
                            <a-input
                                v-model="modelForm.length"
                                placeholder="请输入数据精度" />
                        </a-form-model-item>
                        <a-form-model-item ref="unit" label="单位" prop="unit">
                            <a-input
                                v-model="modelForm.unit"
                                placeholder="请自定义数据单位" />
                        </a-form-model-item>
                    </div>
                    <div v-show="arrayCon === 2">
                        <a-form-model-item ref="arrayDataLen" label="数据长度" prop="arrayDataLen">
                            <a-input
                                v-model="modelForm.arrayDataLen"
                                style="display: inline-block; width: 80%; margin-right: 12px"
                                placeholder="整数最大256，单位：字符" /> 字符
                        </a-form-model-item>
                    </div>
                    <div v-show="arrayCon === 3">
                        <a-form-model-item label="时间格式">
                            <a-input
                                disabled
                                placeholder="整数类型Int64的UTC时间戳(毫秒)" />
                        </a-form-model-item>
                    </div>
                    <a-form-model-item ref="readType" label="读写类型" prop="readType">
                        <a-select v-model="modelForm.readType">
                            <a-select-option value="rw">
                                读写
                            </a-select-option>
                            <a-select-option value="r">
                                只读
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div v-show="indexs === 7">
                    <p>
                        <span>输出参数</span>
                        <span style="margin-left: 50px; color: #999">最多支持50个参数</span>
                    </p>
                    <a-button type="dashed" style="width: 60%" @click="isVisible = true">
                        <a-icon type="plus" /> 添加参数
                    </a-button>
                    <ul style="margin-top: 16px">
                        <li v-for="(item, index) in modelForm.paramList" :key="index" class="paramWrap">
                            <span>{{ item.name }}</span>
                            <span>{{ item.sign }}</span>
                            <span>{{ item.dataType }}</span>
                            <span style="cursor: pointer; color: #1890ff">查看</span>
                        </li>
                    </ul>
                </div>
                <a-form-model-item label="描述" prop="desc">
                    <a-textarea
                        v-model="modelForm.desc"
                        placeholder="请输入描述内容" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <EventsDialog :is-dialog-show="isVisible" :data-list="modelForm.paramList" @cancel="isVisible = false" @onSave="onSaveParam" />
    </div>
</template>

<script>
import EventsDialog from "@/views/dashboard/equipmentManagement/productList/components/EventsDialog"
import { validateInt, validatePass} from "@/utils/validate"

export default {
    name: "Dialog",
    components: {
        EventsDialog
    },
    props: {
        isDialogShow: {
            type: Boolean,
            default: false
        },
        dataList: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    data() {
        return{
            isVisible: false,
            // 当前选择的功能类型
            chooseFunType: '',
            // 当前选择的数据类型
            chooseType: '',
            // 根据弹窗中选择的数据类型显示相应内容
            indexs: 1,
            // 根据弹窗中选择的元素类型显示相应内容
            arrayCon: 1,
            // 表单数据
            modelForm: {
                id: '',
                paramList: [],
                fnType: 'u',
                name: '',
                type: 'properties',
                dataType: 'int32(整数型)',
                arrayDataType: 'int32',
                // eventType: 'info',
                sign: '',
                readType: 'r',
                arrayDataLen: '',
                intMin: '',
                intMax: '',
                arrayIntMin: '',
                arrayIntMax: '',
                boolT: '',
                boolF: '',
                length: '',
                unit: '',
                len: '',
                dataLen: '',
                domains: [],
                firm: '',
                desc: ''
            },
            // 表单验证规则
            modelRules: {
                name: [
                    { required: true, message: '请输入功能名称', trigger: 'blur' },
                    { validator: this.validateName, trigger: 'blur' },
                ],
                type: [{ required: true, message: '请选择功能类型', trigger: 'change' }],
                arrayDataType: [{ required: true, message: '请选择元素类型', trigger: 'change' }],
                // eventType: [{ required: true, message: '请选择事件类型', trigger: 'change' }],
                dataType: [{ required: true, message: '请选择数据类型', trigger: 'change' }],
                sign: [
                    { required: true, message: '请输入标识符', trigger: 'blur' },
                    { validator: this.validateSign, trigger: 'blur'}
                ],
                readType: [{ required: true, message: '请选择读写类型', trigger: 'change' }],
                intMin: [
                    { required: true, message: '请输入最小值', trigger: 'blur' },
                    { validator: this.validateMin, trigger: 'blur' },
                ],
                intMax:[
                    { required: true, message: '请输入最大值', trigger: 'blur' },
                    { validator: this.validateMax, trigger: 'blur' },
                ]
            }
        }
    },
    methods: {
        // 正则匹配数字、最小值必须小于最大值
        validateMin(rule, value, callback) {
            let max = this.modelForm.intMax
            let re = /^[+-]?\d+\.?\d*$/g, r
            r = re.test(value)
            if (value.length > 0 && !r) {
                callback(new Error('只能输入数字！'))
                return
            } else if (max !== '' && parseInt(value) > parseInt(max)) {
                callback(new Error('最小值需要小于最大值！'))
            }
            callback()
        },
        // 正则匹配数字、最大值必须大于最小值
        validateMax(rule, value, callback) {
            let min = this.modelForm.intMin
            let re = /^[+-]?\d+\.?\d*$/g, r
            r = re.test(value)
            if (value.length > 0 && !r) {
                callback(new Error('只能输入数字！'))
                return
            } else if (min !== '' && parseInt(value) < parseInt(min)) {
                callback(new Error('最大值需要大于最小值！'))
            }
            callback()
        },
        // 功能名称正则匹配及唯一性判断
        validateName(rule, value, callback) {
            let re = /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z])/, r
            r = re.test(value)
            let info = JSON.parse(JSON.stringify(this.dataList)),
                data = this.modelForm.name
            info.forEach((item) => {
                if (item.name === data) {
                    callback(new Error('该功能名称已存在，请重新输入！'))
                    return
                }
            })
            if (value.length > 0 && !r) {
                callback(new Error('请输入中文，英文，数字及特殊字符，必须以中文或英文开头！'))
                return
            }
            if (value.length > 32) {
                callback(new Error('输入最大长度为32位！'))
                return
            }
            callback()
        },
        // 标识符正则匹配及唯一性判断
        validateSign(rule, value, callback) {
            let re = /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z])/, r
            r = re.test(value)
            let info = JSON.parse(JSON.stringify(this.dataList)),
                sign = this.modelForm.sign
            info.forEach((item) => {
                if (item.sign === sign) {
                    callback(new Error('该标识符已存在，请重新输入！'))
                    return
                }
            })
            if (value.length > 0 && !r) {
                callback(new Error('请输入中文，英文，数字及特殊字符，必须以中文或英文开头！'))
                return
            }
            if (value.length > 32) {
                callback(new Error('输入最大长度为32位！'))
                return
            }
            callback()
        },
        // 点击弹窗中的保存按钮
        onSave() {
            this.$refs.modelRuleForm.validate(valid => {
                if (valid) {
                    if(this.chooseType === 'enum(枚举)' && this.modelForm.domains.length === 0) {
                        this.$message.warn('枚举项不能为空!')
                        return
                    }
                    if(this.chooseFunType === 'events' && this.modelForm.paramList.length === 0) {
                        this.$message.warn('输出参数不能为空!')
                        return
                    }
                    this.chooseFunType = 'properties'
                    this.modelForm.id = new Date().getTime()
                    this.$emit('onSave', this.modelForm)
                    this.$refs.modelRuleForm.resetFields()
                    this.indexs = 1
                    this.modelForm.paramList = []
                    let len = this.modelForm.domains
                    if (len.length > 0) {
                        len.splice(0, len.length)
                    }
                    this.modelRules.intMin = [
                        { required: true, message: '请输入最小值', trigger: 'blur' },
                        { validator: this.validateMin, trigger: 'blur' },
                    ]
                    this.modelRules.intMax = [
                        { required: true, message: '请输入最大值', trigger: 'blur' },
                        { validator: this.validateMax, trigger: 'blur' },
                    ]
                    this.$delete(this.modelRules, 'dataLen')
                    this.$delete(this.modelRules, 'len')
                    this.$delete(this.modelRules, 'boolT')
                    this.$delete(this.modelRules, 'boolF')
                }
            })
        },
        // 点击添加输出参数弹窗中的保存按钮
        onSaveParam(val) {
            this.modelForm.paramList.push(JSON.parse(JSON.stringify(val)))
            this.isVisible = false
        },
        // 选择弹窗中的数据类型
        chooseDataType(val) {
            this.chooseType = val
            this.$delete(this.modelRules, 'intMin')
            this.$delete(this.modelRules, 'intMax')
            if (val === 'int32(整数型)' || val === 'int64(长整数型)' || val === 'float(单精度浮点型)' || val === 'double(双精度浮点型)') {
                console.log(1)
                this.modelRules.intMin = [
                    { required: true, message: '请输入最小值', trigger: 'blur' },
                    { validator: this.validateMin, trigger: 'blur' },
                ]
                this.modelRules.intMax = [
                    { required: true, message: '请输入最大值', trigger: 'blur' },
                    { validator: this.validateMax, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'intMin')
                this.$delete(this.modelRules, 'intMax')
            }
            if (val === 'string(字符串)' || val === 'file(文件)' || val === 'password(密码)' || val === 'geopoint(地理位置)') {
                this.modelRules.dataLen = [
                    { required: true, message: '请输入数据长度', trigger: 'blur' },
                    { validator: validateInt, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'dataLen')
            }
            if (val === 'array(数组)') {
                this.arrayCon = 1
                this.$delete(this.modelRules, 'arrayDataLen')
                this.modelRules.len = [
                    { required: true, message: '请输入元素个数', trigger: 'blur' },
                    { validator: validateInt, trigger: 'blur' },
                ]
                this.modelRules.arrayIntMin = [
                    { required: true, message: '请输入最小值', trigger: 'blur' },
                    { validator: this.validateMin, trigger: 'blur' },
                ]
                this.modelRules.arrayIntMax = [
                    { required: true, message: '请输入最大值', trigger: 'blur' },
                    { validator: this.validateMax, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'len')
                this.$delete(this.modelRules, 'arrayIntMin')
                this.$delete(this.modelRules, 'arrayIntMax')
            }
            if (val === 'bool(布尔)') {
                this.modelRules.boolT = [
                    { required: true, message: '请输入描述', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' },
                ]
                this.modelRules.boolF = [
                    { required: true, message: '请输入描述', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'boolT')
                this.$delete(this.modelRules, 'boolF')
            }
            const statusHandlers = {
                'int32(整数型)': 1,
                'int64(长整数型)': 1,
                'float(单精度浮点型)': 1,
                'double(双精度浮点型)': 1,
                'enum(枚举)': 2,
                'bool(布尔)': 3,
                'string(字符串)': 4,
                'date(时间)': 5,
                'array(数组)': 6
            }
            return this.indexs = statusHandlers[val] || (this.indexs = 4)
        },
        // 移除弹窗中的枚举项
        removeDomain(item) {
            let index = this.modelForm.domains.indexOf(item)
            if (index !== -1) {
                this.modelForm.domains.splice(index, 1)
            }
        },
        // 选择弹窗中的元素类型
        chooseArrayType(val) {
            this.$delete(this.modelRules, 'arrayIntMin')
            this.$delete(this.modelRules, 'arrayIntMax')
            if (val === 'int32' || val === 'int64' || val === 'float' || val === 'double') {
                this.modelRules.arrayIntMin = [
                    { required: true, message: '请输入最小值', trigger: 'blur' },
                    { validator: this.validateMin, trigger: 'blur' },
                ]
                this.modelRules.arrayIntMax = [
                    { required: true, message: '请输入最大值', trigger: 'blur' },
                    { validator: this.validateMax, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'arrayIntMin')
                this.$delete(this.modelRules, 'arrayIntMax')
            }
            if (val === 'string') {
                this.modelRules.arrayDataLen = [
                    { required: true, message: '请输入数据长度', trigger: 'blur' },
                    { validator: validateInt, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'arrayDataLen')
            }
            const statusHandlers = {
                'int32': 1,
                'int64': 1,
                'float': 1,
                'double': 1,
                'string': 2,
                'date': 3
            }
            return this.arrayCon = statusHandlers[val]
        },
        // 新增添弹窗中的枚举项
        addDomain() {
            this.modelForm.domains.push({
                value: '',
                desc: '',
                key: Date.now(),
            })
        },
        // 选择弹窗中的功能类型
        chooseFnType(val) {
            this.chooseFunType = val
            this.indexs = val === 'events' ? 7 : 1
            if (val === 'events') {
                this.$delete(this.modelRules, 'dataLen')
                this.$delete(this.modelRules, 'len')
                this.$delete(this.modelRules, 'boolT')
                this.$delete(this.modelRules, 'boolF')
                this.$delete(this.modelRules, 'intMin')
                this.$delete(this.modelRules, 'intMax')
            } else if (val === 'properties') {
                this.$refs.modelRuleForm.resetFields()
                this.modelRules.intMin = [
                    { required: true, message: '请输入最小值', trigger: 'blur' },
                    { validator: this.validateMin, trigger: 'blur' },
                ]
                this.modelRules.intMax = [
                    { required: true, message: '请输入最大值', trigger: 'blur' },
                    { validator: this.validateMax, trigger: 'blur' },
                ]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .hiddenLabel .ant-form-item-label{
        opacity: 0;
    }
    .paramWrap {
        display: flex;
        padding: 10px 16px;
        margin-bottom: 12px;
        justify-content: space-between;
        background: #f0f4f8;
    }
</style>
