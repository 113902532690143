var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-range-picker", {
    attrs: {
      "show-time": "",
      placeholder: _vm.tip,
      format: "YYYY-MM-DD HH:mm:ss",
      locale: _vm.locale,
    },
    on: { ok: _vm.onChoose, change: _vm.onChange },
    model: {
      value: _vm.datesChange,
      callback: function ($$v) {
        _vm.datesChange = $$v
      },
      expression: "datesChange",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }