<template>
    <a-modal
        :width="800"
        cancel-text="取消"
        ok-text="保存"
        :title="dialogTitle"
        :visible="isDialogShow"
        @ok="onSave"
        v-on="$listeners">
        <a-form-model
            ref="modelRuleForm"
            :model="modelForm"
            :rules="modelRules">
            <a-form-model-item ref="name" label="参数名称" prop="name">
                <a-input
                    v-model="modelForm.name"
                    placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
            </a-form-model-item>
            <a-form-model-item ref="sign" label="标识符" prop="sign">
                <a-input
                    v-model="modelForm.sign"
                    placeholder="最长不超过32位，英文，数字及特殊字符，必须以英文开头" />
            </a-form-model-item>
            <a-form-model-item ref="dataType" label="数据类型" prop="dataType">
                <a-select v-model="modelForm.dataType" @change="chooseDataType">
                    <a-select-option value="int32">
                        int32(整数型)
                    </a-select-option>
                    <a-select-option value="int64">
                        int64(长整数型)
                    </a-select-option>
                    <a-select-option value="float">
                        float(单精度浮点型)
                    </a-select-option>
                    <a-select-option value="double">
                        double(双精度浮点型)
                    </a-select-option>
                    <a-select-option value="enum">
                        enum(枚举)
                    </a-select-option>
                    <a-select-option value="bool">
                        bool(布尔)
                    </a-select-option>
                    <a-select-option value="string">
                        string(字符串)
                    </a-select-option>
                    <a-select-option value="date">
                        date(时间)
                    </a-select-option>
                    <a-select-option value="array">
                        array(数组)
                    </a-select-option>
                    <a-select-option value="file">
                        file
                    </a-select-option>
                    <a-select-option value="password">
                        password
                    </a-select-option>
                    <a-select-option value="geopoint">
                        geopoint(地理位置)
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <div v-show="indexs === 1">
                <a-form-model-item ref="intMin" style="display: inline-block; width: 27%" label="定义取值范围" prop="intMin">
                    <a-input
                        v-model="modelForm.intMin"
                        placeholder="最小值" />
                </a-form-model-item>
                <a-form-model-item class="hiddenLabel" style="display: inline-block; margin: 0 2%" label="-">
                    <span>——</span>
                </a-form-model-item>
                <a-form-model-item ref="intMax" class="hiddenLabel" style="display: inline-block; width: 27%" label="定义取值范围" prop="intMax">
                    <a-input
                        v-model="modelForm.intMax"
                        placeholder="最大值" />
                </a-form-model-item>
                <a-form-model-item ref="length" style="display: inline-block; width: 47%" label="步长" prop="length">
                    <a-input
                        v-model="modelForm.length"
                        placeholder="请输入数据精度" />
                </a-form-model-item>
                <a-form-model-item ref="unit" style="display: inline-block; width: 47%; margin-left: 6%" label="单位" prop="unit">
                    <a-input
                        v-model="modelForm.unit"
                        placeholder="请自定义数据单位" />
                </a-form-model-item>
            </div>
            <div v-show="indexs === 2">
                <p>
                    <span>枚举项</span>
                    <span style="margin-left: 100px; color: #999">最多100项</span>
                </p>
                <p>
                    <span>参数值</span>
                    <span style="margin-left: 27%">参数描述</span>
                </p>
                <a-form-model-item
                    v-for="(domain, index) in modelForm.domains"
                    :key="domain.key"
                    :prop="'domains.' + index + '.value'"
                    :rules="{
                        required: true,
                        message: '参数值和描述不能为空!',
                        trigger: 'blur',
                    }">
                    <a-input
                        v-model="domain.value"
                        placeholder="请输入参数值"
                        style="width: 30%; display: inline-block" />
                    <a-input
                        v-model="domain.desc"
                        placeholder="请输入参数描述"
                        style="width: 55%; display: inline-block; margin: 0 10px 0 2%" />
                    <a-icon
                        v-if="modelForm.domains.length > 1"
                        class="dynamic-delete-button"
                        type="minus-circle-o"
                        :disabled="modelForm.domains.length === 1"
                        @click="removeDomain(domain)" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="dashed" style="width: 60%" @click="addDomain">
                        <a-icon type="plus" /> 添加枚举项
                    </a-button>
                </a-form-model-item>
            </div>
            <div v-show="indexs === 3">
                <a-form-model-item ref="boolT" label="布尔值" prop="boolT">
                    true - <a-input
                        v-model="modelForm.boolT"
                        style="display: inline-block; width: 80%; margin-left: 12px"
                        placeholder="1-20位，中文、英文、数字及特殊字符_-，必须以中文、英文或数字开头" />
                </a-form-model-item>
                <a-form-model-item ref="boolF" label="" prop="boolF">
                    false - <a-input
                        v-model="modelForm.boolF"
                        style="display: inline-block; width: 80%; margin-left: 12px"
                        placeholder="1-20位，中文、英文、数字及特殊字符_-，必须以中文、英文或数字开头" />
                </a-form-model-item>
            </div>
            <div v-show="indexs === 4">
                <a-form-model-item ref="dataLen" label="数据长度" prop="dataLen">
                    <a-input
                        v-model="modelForm.dataLen"
                        style="display: inline-block; width: 80%; margin-right: 12px"
                        placeholder="整数最大256，单位：字符" /> 字符
                </a-form-model-item>
            </div>
            <div v-show="indexs === 5">
                <a-form-model-item label="时间格式">
                    <a-input
                        disabled
                        placeholder="整数类型Int64的UTC时间戳(毫秒)" />
                </a-form-model-item>
            </div>
            <div v-show="indexs === 6">
                <a-form-model-item ref="arrayDataType" label="元素类型" prop="arrayDataType">
                    <a-select v-model="modelForm.arrayDataType" @change="chooseArrayType">
                        <a-select-option value="int32">
                            int32(整数型)
                        </a-select-option>
                        <a-select-option value="int64">
                            int64(长整数型)
                        </a-select-option>
                        <a-select-option value="float">
                            float(单精度浮点型)
                        </a-select-option>
                        <a-select-option value="double">
                            double(双精度浮点型)
                        </a-select-option>
                        <a-select-option value="string">
                            string(字符串)
                        </a-select-option>
                        <a-select-option value="date">
                            date(时间)
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="len" label="元素个数" prop="len">
                    <a-input
                        v-model="modelForm.len"
                        placeholder="元素最大支持10个" />
                </a-form-model-item>
                <div v-show="arrayCon === 1">
                    <a-form-model-item ref="arrayIntMin" style="display: inline-block; width: 27%" label="定义取值范围" prop="arrayIntMin">
                        <a-input
                            v-model="modelForm.arrayIntMin"
                            placeholder="最小值" />
                    </a-form-model-item>
                    <a-form-model-item class="hiddenLabel" style="display: inline-block; margin: 0 2%" label="-">
                        <span>——</span>
                    </a-form-model-item>
                    <a-form-model-item ref="arrayIntMax" class="hiddenLabel" style="display: inline-block; width: 27%" label="定义取值范围" prop="arrayIntMax">
                        <a-input
                            v-model="modelForm.arrayIntMax"
                            placeholder="最大值" />
                    </a-form-model-item>
                    <a-form-model-item ref="length" label="步长" prop="length">
                        <a-input
                            v-model="modelForm.length"
                            placeholder="请输入数据精度" />
                    </a-form-model-item>
                    <a-form-model-item ref="unit" label="单位" prop="unit">
                        <a-input
                            v-model="modelForm.unit"
                            placeholder="请自定义数据单位" />
                    </a-form-model-item>
                </div>
                <div v-show="arrayCon === 2">
                    <a-form-model-item ref="arrayDataLen" label="数据长度" prop="arrayDataLen">
                        <a-input
                            v-model="modelForm.arrayDataLen"
                            style="display: inline-block; width: 80%; margin-right: 12px"
                            placeholder="整数最大256，单位：字符" /> 字符
                    </a-form-model-item>
                </div>
                <div v-show="arrayCon === 3">
                    <a-form-model-item label="时间格式">
                        <a-input
                            disabled
                            placeholder="整数类型Int64的UTC时间戳(毫秒)" />
                    </a-form-model-item>
                </div>
            </div>
        </a-form-model>
    </a-modal>
</template>

<script>
import {validateEn, validateInt, validatePass} from "@/utils/validate"

export default {
    name: "EventsDialog",
    props: {
        isDialogShow: {
            type: Boolean,
            default: false
        },
        dialogTitle: {
            type: String,
            default: '添加输出参数'
        }
    },
    data() {
        return{
            // 当前选择的数据类型
            chooseType: '',
            // 根据弹窗中选择的数据类型显示相应校验内容
            indexs: 1,
            // 根据弹窗中选择的元素类型显示相应内容
            arrayCon: 1,
            // 表单数据
            modelForm: {
                fnType: 'u',
                name: '',
                dataType: 'int32',
                arrayDataType: 'int32',
                arrayDataLen: '',
                sign: '',
                intMin: '',
                intMax: '',
                arrayIntMin: '',
                arrayIntMax: '',
                boolT: '',
                boolF: '',
                length: '',
                unit: '',
                len: '',
                dataLen: '',
                domains: [],
            },
            // 表单验证规则
            modelRules: {
                name: [
                    { required: true, message: '请输入功能名称', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' },
                ],
                dataType: [{ required: true, message: '请选择数据类型', trigger: 'change' }],
                sign: [
                    { required: true, message: '请输入标识符', trigger: 'blur' },
                    { validator: validateEn, trigger: 'blur'}
                ],
                intMin: [
                    { required: true, message: '请输入最小值', trigger: 'blur' },
                    { validator: this.validateMin, trigger: 'blur' },
                ],
                intMax:[
                    { required: true, message: '请输入最大值', trigger: 'blur' },
                    { validator: this.validateMax, trigger: 'blur' },
                ]
            }
        }
    },
    methods: {
        // 正则匹配数字、最小值必须小于最大值
        validateMin(rule, value, callback) {
            let max = this.modelForm.intMax
            let re = /^[+-]?\d+\.?\d*$/g, r
            r = re.test(value)
            if (value.length > 0 && !r) {
                callback(new Error('只能输入数字！'))
                return
            } else if (max !== '' && parseInt(value) > parseInt(max)) {
                callback(new Error('最小值需要小于最大值！'))
            }
            callback()
        },
        // 正则匹配数字、最大值必须大于最小值
        validateMax(rule, value, callback) {
            let min = this.modelForm.intMin
            let re = /^[+-]?\d+\.?\d*$/g, r
            r = re.test(value)
            if (value.length > 0 && !r) {
                callback(new Error('只能输入数字！'))
                return
            } else if (min !== '' && parseInt(value) < parseInt(min)) {
                callback(new Error('最大值需要大于最小值！'))
            }
            callback()
        },
        // 选择弹窗中的元素类型
        chooseArrayType(val) {
            this.$delete(this.modelRules, 'intMin')
            this.$delete(this.modelRules, 'intMax')
            if (val === 'int32' || val === 'int64' || val === 'float' || val === 'double') {
                this.modelRules.intMin = [
                    { required: true, message: '请输入最小值', trigger: 'blur' },
                    { validator: this.validateMin, trigger: 'blur' },
                ]
                this.modelRules.intMax = [
                    { required: true, message: '请输入最大值', trigger: 'blur' },
                    { validator: this.validateMax, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'intMin')
                this.$delete(this.modelRules, 'intMax')
            }
            if (val === 'string') {
                this.modelRules.arrayDataLen = [
                    { required: true, message: '请输入数据长度', trigger: 'blur' },
                    { validator: validateInt, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'arrayDataLen')
            }
            const statusHandlers = {
                'int32': 1,
                'int64': 1,
                'float': 1,
                'double': 1,
                'string': 2,
                'date': 3
            }
            return this.arrayCon = statusHandlers[val]
        },
        // 点击弹窗中的保存按钮
        onSave() {
            this.$refs.modelRuleForm.validate(valid => {
                if (valid) {
                    if(this.chooseType === 'enum' && this.modelForm.domains.length === 0) {
                        this.$message.warn('枚举项不能为空!')
                        return
                    }
                    this.modelForm.id = new Date().getTime()
                    this.$emit('onSave', this.modelForm)
                    this.$refs.modelRuleForm.resetFields()
                    this.indexs = 1
                    let len = this.modelForm.domains
                    if (len.length > 0) {
                        len.splice(0, len.length)
                    }
                    this.modelRules.intMin = [
                        { required: true, message: '请输入最小值', trigger: 'blur' },
                        { validator: this.validateMin, trigger: 'blur' },
                    ]
                    this.modelRules.intMax = [
                        { required: true, message: '请输入最大值', trigger: 'blur' },
                        { validator: this.validateMax, trigger: 'blur' },
                    ]
                    this.$delete(this.modelRules, 'dataLen')
                    this.$delete(this.modelRules, 'len')
                    this.$delete(this.modelRules, 'boolT')
                    this.$delete(this.modelRules, 'boolF')
                }
            })
        },
        // 选择添加自定义功能点弹窗中的数据类型
        chooseDataType(val) {
            this.chooseType = val
            this.$delete(this.modelRules, 'intMin')
            this.$delete(this.modelRules, 'intMax')
            if (val === 'int32' || val === 'int64' || val === 'float' || val === 'double') {
                this.modelRules.intMin = [
                    { required: true, message: '请输入最小值', trigger: 'blur' },
                    { validator: this.validateMin, trigger: 'blur' },
                ]
                this.modelRules.intMax = [
                    { required: true, message: '请输入最大值', trigger: 'blur' },
                    { validator: this.validateMax, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'intMin')
                this.$delete(this.modelRules, 'intMax')
            }
            if (val === 'string' || val === 'file' || val === 'password' || val === 'geopoint') {
                this.modelRules.dataLen = [
                    { required: true, message: '请输入数据长度', trigger: 'blur' },
                    { validator: validateInt, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'dataLen')
            }
            if (val === 'array') {
                this.arrayCon = 1
                this.$delete(this.modelRules, 'arrayDataLen')
                this.modelRules.len = [
                    { required: true, message: '请输入数据长度', trigger: 'blur' },
                    { validator: validateInt, trigger: 'blur' },
                ]
                this.modelRules.arrayIntMin = [
                    { required: true, message: '请输入最小值', trigger: 'blur' },
                    { validator: this.validateMin, trigger: 'blur' },
                ]
                this.modelRules.arrayIntMax = [
                    { required: true, message: '请输入最大值', trigger: 'blur' },
                    { validator: this.validateMax, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'len')
                this.$delete(this.modelRules, 'arrayIntMin')
                this.$delete(this.modelRules, 'arrayIntMax')
            }
            if (val === 'bool') {
                this.modelRules.boolT = [
                    { required: true, message: '请输入描述', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' },
                ]
                this.modelRules.boolF = [
                    { required: true, message: '请输入描述', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' },
                ]
            } else {
                this.$delete(this.modelRules, 'boolT')
                this.$delete(this.modelRules, 'boolF')
            }
            const statusHandlers = {
                'int32': 1,
                'int64': 1,
                'float': 1,
                'double': 1,
                'enum': 2,
                'bool': 3,
                'string': 4,
                'date': 5,
                'array': 6
            }
            return this.indexs = statusHandlers[val] || (this.indexs = 4)
        },
        // 移除弹窗中的枚举项
        removeDomain(item) {
            let index = this.modelForm.domains.indexOf(item)
            if (index !== -1) {
                this.modelForm.domains.splice(index, 1)
            }
        },
        // 新增添弹窗中的枚举项
        addDomain() {
            this.modelForm.domains.push({
                value: '',
                desc: '',
                key: Date.now(),
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .hiddenLabel .ant-form-item-label{
        opacity: 0;
    }
</style>
