var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    _vm._g(
      {
        attrs: {
          width: 800,
          "cancel-text": "取消",
          "ok-text": "保存",
          title: _vm.dialogTitle,
          visible: _vm.isDialogShow,
        },
        on: { ok: _vm.onSave },
      },
      _vm.$listeners
    ),
    [
      _c(
        "a-form-model",
        {
          ref: "modelRuleForm",
          attrs: { model: _vm.modelForm, rules: _vm.modelRules },
        },
        [
          _c(
            "a-form-model-item",
            { ref: "name", attrs: { label: "参数名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder:
                    "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                },
                model: {
                  value: _vm.modelForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.modelForm, "name", $$v)
                  },
                  expression: "modelForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { ref: "sign", attrs: { label: "标识符", prop: "sign" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder:
                    "最长不超过32位，英文，数字及特殊字符，必须以英文开头",
                },
                model: {
                  value: _vm.modelForm.sign,
                  callback: function ($$v) {
                    _vm.$set(_vm.modelForm, "sign", $$v)
                  },
                  expression: "modelForm.sign",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { ref: "dataType", attrs: { label: "数据类型", prop: "dataType" } },
            [
              _c(
                "a-select",
                {
                  on: { change: _vm.chooseDataType },
                  model: {
                    value: _vm.modelForm.dataType,
                    callback: function ($$v) {
                      _vm.$set(_vm.modelForm, "dataType", $$v)
                    },
                    expression: "modelForm.dataType",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "int32" } }, [
                    _vm._v(" int32(整数型) "),
                  ]),
                  _c("a-select-option", { attrs: { value: "int64" } }, [
                    _vm._v(" int64(长整数型) "),
                  ]),
                  _c("a-select-option", { attrs: { value: "float" } }, [
                    _vm._v(" float(单精度浮点型) "),
                  ]),
                  _c("a-select-option", { attrs: { value: "double" } }, [
                    _vm._v(" double(双精度浮点型) "),
                  ]),
                  _c("a-select-option", { attrs: { value: "enum" } }, [
                    _vm._v(" enum(枚举) "),
                  ]),
                  _c("a-select-option", { attrs: { value: "bool" } }, [
                    _vm._v(" bool(布尔) "),
                  ]),
                  _c("a-select-option", { attrs: { value: "string" } }, [
                    _vm._v(" string(字符串) "),
                  ]),
                  _c("a-select-option", { attrs: { value: "date" } }, [
                    _vm._v(" date(时间) "),
                  ]),
                  _c("a-select-option", { attrs: { value: "array" } }, [
                    _vm._v(" array(数组) "),
                  ]),
                  _c("a-select-option", { attrs: { value: "file" } }, [
                    _vm._v(" file "),
                  ]),
                  _c("a-select-option", { attrs: { value: "password" } }, [
                    _vm._v(" password "),
                  ]),
                  _c("a-select-option", { attrs: { value: "geopoint" } }, [
                    _vm._v(" geopoint(地理位置) "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.indexs === 1,
                  expression: "indexs === 1",
                },
              ],
            },
            [
              _c(
                "a-form-model-item",
                {
                  ref: "intMin",
                  staticStyle: { display: "inline-block", width: "27%" },
                  attrs: { label: "定义取值范围", prop: "intMin" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "最小值" },
                    model: {
                      value: _vm.modelForm.intMin,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelForm, "intMin", $$v)
                      },
                      expression: "modelForm.intMin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "hiddenLabel",
                  staticStyle: { display: "inline-block", margin: "0 2%" },
                  attrs: { label: "-" },
                },
                [_c("span", [_vm._v("——")])]
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "intMax",
                  staticClass: "hiddenLabel",
                  staticStyle: { display: "inline-block", width: "27%" },
                  attrs: { label: "定义取值范围", prop: "intMax" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "最大值" },
                    model: {
                      value: _vm.modelForm.intMax,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelForm, "intMax", $$v)
                      },
                      expression: "modelForm.intMax",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "length",
                  staticStyle: { display: "inline-block", width: "47%" },
                  attrs: { label: "步长", prop: "length" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入数据精度" },
                    model: {
                      value: _vm.modelForm.length,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelForm, "length", $$v)
                      },
                      expression: "modelForm.length",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "unit",
                  staticStyle: {
                    display: "inline-block",
                    width: "47%",
                    "margin-left": "6%",
                  },
                  attrs: { label: "单位", prop: "unit" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请自定义数据单位" },
                    model: {
                      value: _vm.modelForm.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelForm, "unit", $$v)
                      },
                      expression: "modelForm.unit",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.indexs === 2,
                  expression: "indexs === 2",
                },
              ],
            },
            [
              _c("p", [
                _c("span", [_vm._v("枚举项")]),
                _c(
                  "span",
                  { staticStyle: { "margin-left": "100px", color: "#999" } },
                  [_vm._v("最多100项")]
                ),
              ]),
              _c("p", [
                _c("span", [_vm._v("参数值")]),
                _c("span", { staticStyle: { "margin-left": "27%" } }, [
                  _vm._v("参数描述"),
                ]),
              ]),
              _vm._l(_vm.modelForm.domains, function (domain, index) {
                return _c(
                  "a-form-model-item",
                  {
                    key: domain.key,
                    attrs: {
                      prop: "domains." + index + ".value",
                      rules: {
                        required: true,
                        message: "参数值和描述不能为空!",
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    _c("a-input", {
                      staticStyle: { width: "30%", display: "inline-block" },
                      attrs: { placeholder: "请输入参数值" },
                      model: {
                        value: domain.value,
                        callback: function ($$v) {
                          _vm.$set(domain, "value", $$v)
                        },
                        expression: "domain.value",
                      },
                    }),
                    _c("a-input", {
                      staticStyle: {
                        width: "55%",
                        display: "inline-block",
                        margin: "0 10px 0 2%",
                      },
                      attrs: { placeholder: "请输入参数描述" },
                      model: {
                        value: domain.desc,
                        callback: function ($$v) {
                          _vm.$set(domain, "desc", $$v)
                        },
                        expression: "domain.desc",
                      },
                    }),
                    _vm.modelForm.domains.length > 1
                      ? _c("a-icon", {
                          staticClass: "dynamic-delete-button",
                          attrs: {
                            type: "minus-circle-o",
                            disabled: _vm.modelForm.domains.length === 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeDomain(domain)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c(
                "a-form-model-item",
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "60%" },
                      attrs: { type: "dashed" },
                      on: { click: _vm.addDomain },
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v(" 添加枚举项 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.indexs === 3,
                  expression: "indexs === 3",
                },
              ],
            },
            [
              _c(
                "a-form-model-item",
                { ref: "boolT", attrs: { label: "布尔值", prop: "boolT" } },
                [
                  _vm._v(" true - "),
                  _c("a-input", {
                    staticStyle: {
                      display: "inline-block",
                      width: "80%",
                      "margin-left": "12px",
                    },
                    attrs: {
                      placeholder:
                        "1-20位，中文、英文、数字及特殊字符_-，必须以中文、英文或数字开头",
                    },
                    model: {
                      value: _vm.modelForm.boolT,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelForm, "boolT", $$v)
                      },
                      expression: "modelForm.boolT",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "boolF", attrs: { label: "", prop: "boolF" } },
                [
                  _vm._v(" false - "),
                  _c("a-input", {
                    staticStyle: {
                      display: "inline-block",
                      width: "80%",
                      "margin-left": "12px",
                    },
                    attrs: {
                      placeholder:
                        "1-20位，中文、英文、数字及特殊字符_-，必须以中文、英文或数字开头",
                    },
                    model: {
                      value: _vm.modelForm.boolF,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelForm, "boolF", $$v)
                      },
                      expression: "modelForm.boolF",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.indexs === 4,
                  expression: "indexs === 4",
                },
              ],
            },
            [
              _c(
                "a-form-model-item",
                {
                  ref: "dataLen",
                  attrs: { label: "数据长度", prop: "dataLen" },
                },
                [
                  _c("a-input", {
                    staticStyle: {
                      display: "inline-block",
                      width: "80%",
                      "margin-right": "12px",
                    },
                    attrs: { placeholder: "整数最大256，单位：字符" },
                    model: {
                      value: _vm.modelForm.dataLen,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelForm, "dataLen", $$v)
                      },
                      expression: "modelForm.dataLen",
                    },
                  }),
                  _vm._v(" 字符 "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.indexs === 5,
                  expression: "indexs === 5",
                },
              ],
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "时间格式" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: "",
                      placeholder: "整数类型Int64的UTC时间戳(毫秒)",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.indexs === 6,
                  expression: "indexs === 6",
                },
              ],
            },
            [
              _c(
                "a-form-model-item",
                {
                  ref: "arrayDataType",
                  attrs: { label: "元素类型", prop: "arrayDataType" },
                },
                [
                  _c(
                    "a-select",
                    {
                      on: { change: _vm.chooseArrayType },
                      model: {
                        value: _vm.modelForm.arrayDataType,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelForm, "arrayDataType", $$v)
                        },
                        expression: "modelForm.arrayDataType",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "int32" } }, [
                        _vm._v(" int32(整数型) "),
                      ]),
                      _c("a-select-option", { attrs: { value: "int64" } }, [
                        _vm._v(" int64(长整数型) "),
                      ]),
                      _c("a-select-option", { attrs: { value: "float" } }, [
                        _vm._v(" float(单精度浮点型) "),
                      ]),
                      _c("a-select-option", { attrs: { value: "double" } }, [
                        _vm._v(" double(双精度浮点型) "),
                      ]),
                      _c("a-select-option", { attrs: { value: "string" } }, [
                        _vm._v(" string(字符串) "),
                      ]),
                      _c("a-select-option", { attrs: { value: "date" } }, [
                        _vm._v(" date(时间) "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "len", attrs: { label: "元素个数", prop: "len" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "元素最大支持10个" },
                    model: {
                      value: _vm.modelForm.len,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelForm, "len", $$v)
                      },
                      expression: "modelForm.len",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.arrayCon === 1,
                      expression: "arrayCon === 1",
                    },
                  ],
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "arrayIntMin",
                      staticStyle: { display: "inline-block", width: "27%" },
                      attrs: { label: "定义取值范围", prop: "arrayIntMin" },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "最小值" },
                        model: {
                          value: _vm.modelForm.arrayIntMin,
                          callback: function ($$v) {
                            _vm.$set(_vm.modelForm, "arrayIntMin", $$v)
                          },
                          expression: "modelForm.arrayIntMin",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "hiddenLabel",
                      staticStyle: { display: "inline-block", margin: "0 2%" },
                      attrs: { label: "-" },
                    },
                    [_c("span", [_vm._v("——")])]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "arrayIntMax",
                      staticClass: "hiddenLabel",
                      staticStyle: { display: "inline-block", width: "27%" },
                      attrs: { label: "定义取值范围", prop: "arrayIntMax" },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "最大值" },
                        model: {
                          value: _vm.modelForm.arrayIntMax,
                          callback: function ($$v) {
                            _vm.$set(_vm.modelForm, "arrayIntMax", $$v)
                          },
                          expression: "modelForm.arrayIntMax",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { ref: "length", attrs: { label: "步长", prop: "length" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入数据精度" },
                        model: {
                          value: _vm.modelForm.length,
                          callback: function ($$v) {
                            _vm.$set(_vm.modelForm, "length", $$v)
                          },
                          expression: "modelForm.length",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { ref: "unit", attrs: { label: "单位", prop: "unit" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请自定义数据单位" },
                        model: {
                          value: _vm.modelForm.unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.modelForm, "unit", $$v)
                          },
                          expression: "modelForm.unit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.arrayCon === 2,
                      expression: "arrayCon === 2",
                    },
                  ],
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "arrayDataLen",
                      attrs: { label: "数据长度", prop: "arrayDataLen" },
                    },
                    [
                      _c("a-input", {
                        staticStyle: {
                          display: "inline-block",
                          width: "80%",
                          "margin-right": "12px",
                        },
                        attrs: { placeholder: "整数最大256，单位：字符" },
                        model: {
                          value: _vm.modelForm.arrayDataLen,
                          callback: function ($$v) {
                            _vm.$set(_vm.modelForm, "arrayDataLen", $$v)
                          },
                          expression: "modelForm.arrayDataLen",
                        },
                      }),
                      _vm._v(" 字符 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.arrayCon === 3,
                      expression: "arrayCon === 3",
                    },
                  ],
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "时间格式" } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: "",
                          placeholder: "整数类型Int64的UTC时间戳(毫秒)",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }