<template>
    <a-range-picker v-model="datesChange" show-time :placeholder="tip" format="YYYY-MM-DD HH:mm:ss" :locale="locale" @ok="onChoose" @change="onChange" />
    <!-- <a-range-picker v-model="datesChange" show-time :placeholder="tip" :separator="sign" format="YYYY-MM-DD HH:mm:ss" :locale="locale" @ok="onChoose" @change="onChange" /> -->
</template>

<script>
import Vue from 'vue'
// 日期框汉化
import moment from 'moment'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
Vue.prototype.$moment = moment
export default {
    name: "DateRangePicker",
    props: {
        tip: {
            type: Array,
            default: function() {
                return []
            }
        },
        dates: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    data() {
        return {
            locale,
            // 日期范围间隔符
            // sign: '',
            datesChange: this.dates
        }
    },
    watch: {
        dates(val) {
            this.datesChange = val
        }
    },
    methods: {
        onChoose(val) {
            this.$emit('chooseDate', val)
        },
        onChange(val) {
            this.$emit('dateClear', val.length)
            // this.sign = val.length === 0 ? '' : '~'
        }
    }
}
</script>

<style scoped>

</style>
