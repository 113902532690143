<template>
    <div class="detailsWrap">
        <!-- <p style="border-bottom: 1px solid #ccc; padding-bottom: 10px">
            <router-link to="/product-list">
                <a-icon type="rollback" /> 回到产品列表
            </router-link>
        </p> -->
        <div>
            <span class="detailsTitle">产品信息</span>
            <span style="cursor: pointer" @click="editProduct"><a-icon type="edit" /> 编辑产品信息</span>
            <div class="detailsList">
                <ul>
                    <li>产品Code：{{ productInfo.code }}</li>
                    <li>接入协议：{{ productInfo.accessProtocolName }}</li>
                    <li>设备数量：{{ productInfo.deviceCount }}</li>
                    <li>联网类型：{{ productInfo.networkMode }}</li>
                    <li>产品描述：{{ productInfo.remark }}</li>
                </ul>
                <ul>
                    <li>节点类型：{{ productInfo.nodeType | nodeTypeFilter }}</li>
                    <li>创建时间：{{ productInfo.createTime }}</li>
                    <li>产品key：{{ productInfo.keyInfo }}</li>
                    <li v-if="productInfo.accessProtocolName === 'MQTT'">
                        clientid: {{ productInfo.mqttClientId }}
                    </li>
                    <li>所属省市：{{ productInfo.provinceName }}{{ productInfo.cityName }}</li>
                </ul>
                <ul>
                    <li>产品类型：{{ productInfo.categoryName }}</li>
                    <li>产品名称：{{ productInfo.productName }}</li>
                    <li>产品厂商：{{ productInfo.manufacturer }}</li>
                    <li v-if="productInfo.accessProtocolName === 'MQTT'">
                        username: {{ productInfo.mqttUserName }}
                    </li>
                </ul>
            </div>
            <div class="card-container">
                <a-tabs type="card">
                    <a-tab-pane key="1" tab="设备档案">
                        <div style="display: flex; margin-bottom: 12px">
                            <a-select v-model="equipStatus" style="width: 220px">
                                <a-select-option value="all">
                                    设备状态（全部）
                                </a-select-option>
                                <a-select-option value="0">
                                    未激活
                                </a-select-option>
                                <a-select-option value="1">
                                    在线
                                </a-select-option>
                                <a-select-option value="2">
                                    离线
                                </a-select-option>
                            </a-select>
                            <a-select v-model="createType" style="width: 220px; margin: 0 100px 0 30px">
                                <a-select-option value="all">
                                    创建方式（全部）
                                </a-select-option>
                                <a-select-option value="0">
                                    人工注册
                                </a-select-option>
                                <a-select-option value="1">
                                    自动注册
                                </a-select-option>
                            </a-select>
                            <a-input v-model="keyword" style="width: 400px" placeholder="请输入设备code" :allow-clear="true" />
                        </div>
                        <div style="margin-bottom: 12px">
                            <DateRangePicker :tip="['请选择最近在线时间段', '']" :dates="nowDates" @chooseDate="nowDate" @dateClear="onlineDateClear" />
                            <DateRangePicker :tip="['请选择最近添加时间段', '']" :dates="nowAddDates" style="margin-left: 20px" @chooseDate="nowAddDate" @dateClear="nowDateClear" />
                            <a-button style="margin: 0 20px" @click="resetDate">
                                重置
                            </a-button>
                            <a-button type="primary" @click="searchProduct">
                                查询
                            </a-button>
                        </div>
                        <p style="text-align: right">
                            <a-button type="primary">
                                导出设备
                            </a-button>
                        </p>
                        <a-table style="margin-bottom: 20px" row-key="id" :locale="{emptyText: '暂无数据'}" :columns="columns" :pagination="false" :data-source="data">
                            <template slot="status" slot-scope="text, record">
                                <span>{{ record.status | statusTypeFilter }}</span>
                            </template>
                            <template slot="source" slot-scope="text, record">
                                <span>{{ record.source | sourceTypeFilter }}</span>
                            </template>
                            <template slot="operation" slot-scope="text, record">
                                <a href="javascript:void(0);" style="color: #1890ff" @click="gotoDetails(record)">详情</a>
                            </template>
                        </a-table>
                        <Pagination :current-page="page" :count="total" @handleChange="changePage" @handleChangeSize="changeSize" />
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="物模型管理">
                        <div class="authorizeItem">
                            <p :hidden="setModelFlag" style="text-align: right">
                                <a-button @click="downloadSDK">
                                    下载SDK
                                </a-button>
                                <a-button style="margin: 0 25px" @click="setModel">
                                    设置物模型
                                </a-button>
                                <a-button type="primary">
                                    导出物模型
                                </a-button>
                            </p>
                            <ul v-show="!isShow" style="text-align: center">
                                <li class="setModel" @click="setModel">
                                    <a-icon type="plus-circle" />
                                </li>
                                <li>点击"设置物模型"，开启物模型编辑</li>
                            </ul>
                        </div>
                        <div v-show="setModelFlag">
                            <p style="text-align: center">
                                <a-button>
                                    导入物模型
                                </a-button>
                                <a-button style="margin: 0 25px">
                                    批量删除
                                </a-button>
                                <a-button>
                                    添加系统功能点
                                </a-button>
                                <a-button style="margin-left: 25px" @click="isVisible = true">
                                    添加自定义功能点
                                </a-button>
                            </p>
                            <p style="text-align: right">
                                <a-button style="margin-right: 25px" @click="onSaveList">
                                    保存
                                </a-button>
                                <a-button @click="goBack">
                                    返回
                                </a-button>
                            </p>
                        </div>
                        <div v-show="isShow">
                            <div class="detailsChoose">
                                <div style="display: flex">
                                    <a-select default-value="all" style="width: 220px" @change="funType">
                                        <a-select-option value="all">
                                            功能类型（全部）
                                        </a-select-option>
                                        <a-select-option value="properties">
                                            属性
                                        </a-select-option>
                                        <a-select-option value="events">
                                            事件
                                        </a-select-option>
                                        <a-select-option value="services" disabled>
                                            服务
                                        </a-select-option>
                                    </a-select>
                                    <a-select default-value="all" style="width: 220px; margin: 0 30px 0" @change="funCata">
                                        <a-select-option value="all">
                                            功能类别（全部）
                                        </a-select-option>
                                        <a-select-option value="y" disabled>
                                            系统
                                        </a-select-option>
                                        <a-select-option value="u">
                                            自定义
                                        </a-select-option>
                                        <a-select-option value="st" disabled>
                                            标准
                                        </a-select-option>
                                    </a-select>
                                    <a-input-search v-model="keywords" style="width: 300px" placeholder="请输入功能名称搜索" enter-button @search="onKeySearch" />
                                </div>
                            </div>
                            <a-table style="margin-bottom: 20px" row-key="id" :locale="{emptyText: '暂无数据'}" :columns="setModelColumns" :pagination="false" :data-source="setModelData">
                                <template slot="type" slot-scope="text, record">
                                    <span>{{ record.type | functionTypeFilter }}</span>
                                </template>
                                <template slot="fnType" slot-scope="text, record">
                                    <span>{{ record.fnType | functionCataFilter }}</span>
                                </template>
                                <template slot="dataType" slot-scope="text, record">
                                    <span v-if="record.type === 'events'">-</span>
                                    <span v-else>{{ record.dataType }}</span>
                                </template>
                                <template slot="define" slot-scope="text, record">
                                    <span v-if="record.type === 'events'">-</span>
                                    <!-- <span v-if="record.type === 'events'">事件类型：{{ record.eventType|eventsTypeFilter }}</span> -->
                                    <div v-else v-html="defineFilter(record)" />
                                </template>
                                <template slot="readType" slot-scope="text, record">
                                    <span v-if="record.type === 'events'">-</span>
                                    <span v-else>{{ record.readType | rwTypeFilter }}</span>
                                </template>
                                <template slot="operation" slot-scope="text, record, index">
                                    <a href="javascript:void(0);" style="color: #1890ff; margin-right: 30px" @click="showInfo(record)">查看</a>
                                    <a v-show="isSetModel" href="javascript:void(0);" style="color: #1890ff" @click="onDelete(index)">删除</a>
                                </template>
                            </a-table>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
        <!-- 编辑产品信息弹窗 -->
        <a-modal
            class="addProduct"
            :width="800"
            cancel-text="取消"
            ok-text="确认"
            title="编辑产品信息"
            :visible="visible"
            @ok="onSubmit"
            @cancel="visible = false">
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules">
                <a-form-model-item ref="name" label="产品名称" prop="name">
                    <a-input
                        v-model="form.name"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item ref="city" label="所属省市" prop="city">
                    <CityChoice :prov-val="provVal" :city-val="cityVal" @getProv="getProvInfo" @getCity="getCityInfo" />
                </a-form-model-item>
                <a-form-model-item label="联网方式" prop="delivery">
                    <a-checkbox-group v-model="form.delivery">
                        <a-checkbox value="蜂窝">
                            蜂窝
                        </a-checkbox>
                        <a-checkbox value="以太">
                            以太
                        </a-checkbox>
                        <a-checkbox value="NB">
                            NB
                        </a-checkbox>
                        <a-checkbox value="WIFI">
                            WIFI
                        </a-checkbox>
                        <a-checkbox value="其他">
                            其他
                        </a-checkbox>
                    </a-checkbox-group>
                </a-form-model-item>
                <a-form-model-item style="display: flex" label="产品厂商" prop="firm">
                    <a-input
                        v-model="form.firm"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item style="display: flex" label="产品型号" prop="model">
                    <a-input
                        v-model="form.model"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item style="display: flex" label="产品描述" prop="desc">
                    <a-textarea
                        v-model="form.desc" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <!-- 添加自定义功能点弹窗 -->
        <Dialog :is-dialog-show="isVisible" :data-list="dataList" @cancel="isVisible = false" @onSave="onSave" />
        <!-- 查看自定义功能点弹窗 -->
        <DetailsDialog :is-dialog-show="isShowDialog" :model-form="objInfo" @closeDialog="isShowDialog = false" />
    </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import CityChoice from "@/components/CityChoice"
import DateRangePicker from "@/components/DateRangePicker"
import Dialog from "./components/Dialog"
import DetailsDialog from "./components/DetailsDialog"
import { validatePass } from "@/utils/validate"
import { changeProduct, updateModel, productDetail } from "@/api/product"
import moment from "moment"
import { getEquipList } from "@/api/equipment"
import { setLocalStorage } from "@/utils/localstorage"
const columns = [
    {
        title: '设备code',
        dataIndex: 'code',
        key: 'name'
    },
    {
        title: '设备状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: '添加时间',
        dataIndex: 'createTime',
        key: 'time'
    },
    {
        title: '创建方式',
        dataIndex: 'source',
        scopedSlots: { customRender: 'source' }
    },
    {
        title: '最近在线时间',
        dataIndex: 'latestOnlineTime',
        key: 'onlineTime'
    },
    {
        title: '操作',
        width: '60px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
const  setModelColumns = [
    {
        title: '功能类型',
        dataIndex: 'type',
        scopedSlots: { customRender: 'type' }
    },
    {
        title: '功能类别',
        dataIndex: 'fnType',
        scopedSlots: { customRender: 'fnType' }
    },
    {
        title: '功能名称',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '标识符',
        dataIndex: 'sign',
        key: 'sign'
    },
    {
        title: '数据类型',
        dataIndex: 'dataType',
        scopedSlots: { customRender: 'dataType' }
    },
    {
        title: '数据值定义',
        width: '200px',
        dataIndex: 'intMin',
        scopedSlots: { customRender: 'define' }
    },
    {
        title: '读写类型',
        dataIndex: 'readType',
        scopedSlots: { customRender: 'readType' }
    },
    {
        title: '操作',
        width: '120px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
export default {
    name: "ProductDetails",
    components: {
        Pagination,
        CityChoice,
        DateRangePicker,
        Dialog,
        DetailsDialog
    },
    data() {
        return {
            // 物模型配置后是否需要保存
            isSaveConfig: false,
            // 物模型列表是否显示删除操作
            isSetModel: false,
            // 物模型列表选择的功能类型
            funcType: 'all',
            // 物模型列表选择的功能类别
            catatype: 'all',
            // 存储物模型列表数据
            modelList: [],
            dataList: [],
            // 当前查看的物模型对象
            objInfo: {},
            // 物模型列表输入的功能名称
            keywords: '',
            // 设备列表当前页码
            page: 1,
            // 设备列表每页数据条数
            size: 10,
            // 添加自定义功能点弹窗是否可见
            isVisible: false,
            // 查看自定义功能点弹窗是否可见
            isShowModel: false,
            // 是否为自定义物模型
            isShow: false,
            // 查看物模型弹窗是否显示
            isShowDialog: false,
            // 判断物模型配置后是否被保存
            isSava: false,
            // 设备列表中选择的设备状态
            equipStatus: 'all',
            // 设备列表中选择的创建方式
            createType: 'all',
            // 设备列表中输入的关键字
            keyword: '',
            // 设备列表
            columns,
            data: [],
            // 物模型列表
            setModelColumns,
            setModelData: [],
            setModelFlag: false,
            // 设备列表总条数
            total: 0,
            provVal: '',
            cityVal: '',
            // 产品id
            productId: '',
            // 产品详情数据
            productInfo: {},
            // 编辑产品信息弹窗是否可见
            visible: false,
            // 编辑产品信息表单数据
            form: {
                name: '',
                delivery: undefined,
                firm: '',
                model: '',
                desc: ''
            },
            // 编辑产品信息表单验证规则
            rules: {
                name: [
                    { required: true, message: '请输入产品名称', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' },
                ],
                delivery: [{ required: true, message: '请选择联网方式', trigger: 'change' }],
                firm: [
                    { validator: validatePass, trigger: 'blur' },
                ],
                model: [
                    { validator: validatePass, trigger: 'blur' },
                ]
            },
            // 重置最近在线时间
            nowDates: [],
            // 重置最近添加时间
            nowAddDates: [],
            // 当前选择的最近在线时间
            onlineDate: {},
            // 当前选择的最近添加时间
            addDate: {}
        }
    },
    created() {
        this.productId = this.$route.query.id
        this.getProductDetails()
    },
    methods: {
        // 下载SDK
        downloadSDK() {
            window.location.href = 'https://dcfile.paramland.com/upload_e8760effb156541aba2ef5aaaa5f55da.jar'
        },
        // 循环枚举类型数据集合
        enumData(val) {
            let str = ''
            val.map((item) => {
                str += `<p>${item.value}-${item.desc}</p>`
            })
            return str
        },
        // 添加自定义功能点数据值定义
        defineFilter (type) {
            const defineHandlers = {
                'int32(整数型)': `<p>取值范围：${type.intMin}-${type.intMax}；</p><p>步长：${type.length}；</p><p>单位：${type.unit}</p>`,
                'int64(长整数型)': `<p>取值范围：${type.intMin}-${type.intMax}；</p><p>步长：${type.length}；</p><p>单位：${type.unit}</p>`,
                'float(单精度浮点型)': `<p>取值范围：${type.intMin}-${type.intMax}；</p><p>步长：${type.length}；</p><p>单位：${type.unit}</p>`,
                'double(双精度浮点型)': `<p>取值范围：${type.intMin}-${type.intMax}；</p><p>步长：${type.length}；</p><p>单位：${type.unit}</p>`,
                'enum(枚举)': this.enumData(type.domains),
                'bool(布尔)': `<p>true-${type.boolT}</p><p>false-${type.boolF}</p>`,
                'string(字符串)': `<p>数据长度：${type.dataLen}</p>`,
                'date(时间)': '整数类型Int64的UTC时间戳(毫秒)',
                'array(数组)': `<p>数组个数：${type.len}</p>`,
                'file(文件)': `<p>数据长度：${type.dataLen}</p>`,
                'password(密码)': `<p>数据长度：${type.dataLen}</p>`,
                'geopoint(地理位置)': `<p>数据长度：${type.dataLen}</p>`,
            }
            return defineHandlers[type.dataType] || '-'
        },
        // 获取产品详情数据
        getProductDetails() {
            productDetail(this.productId).then(res => {
                let info = res.data,
                    list = info.metaDataObj !== '' ? JSON.parse(info.metaDataObj) : []
                setLocalStorage('name', info.productName)
                setLocalStorage('url', `/product-list/details?id=${this.productId}`)
                this.productInfo = info
                this.isShow = list.length > 0
                this.setModelData = list || []
                this.modelList = list || []
                this.dataList = list || []
                this.getList(this.page, this.size, name, info.code)
            }).catch(error => {
                console.log(error)
            })
        },
        // 获取设备列表数据
        getList(page, size, name, code, statu, mode, time1, time2, time3, time4) {
            getEquipList(page, size, name, code, statu, mode, time1, time2, time3, time4).then(res => {
                let info = res.data
                this.data = info.records
                this.total = info.total
            }).catch(error => {
                console.log(error)
            })
        },
        // 更新列表数据
        reloadList(page, size) {
            this.page = page
            let status = this.equipStatus !== 'all' ? this.equipStatus : '',
                mode = this.createType !== 'all' ? this.createType : '',
                code = this.productInfo.code
            this.getList(page, size, this.keyword.trim(), code, status, mode, this.addDate.start, this.addDate.end, this.onlineDate.start, this.onlineDate.end)
        },
        // 选择设备列表分页
        changePage(page) {
            this.reloadList(page, this.size)
        },
        // 修改设备列表每页数据条数
        changeSize(size) {
            this.size = size
            this.reloadList(1, size)
        },
        // 编辑产品信息
        editProduct() {
            let info = this.productInfo
            this.provVal = info.provinceName || '请选择省份'
            this.cityVal = info.cityName || '请选择城市'
            this.form = {
                name: info.productName,
                delivery: info.networkMode.split(','),
                firm: info.manufacturer,
                model: info.productModel,
                desc: info.remark
            }
            this.visible = true
        },
        // 获取用户选择的省份数据
        getProvInfo(val) {
            if (this.provVal !== val) {
                this.cityVal = '请选择城市'
            } else {
                this.cityVal = this.cityVal || '请选择城市'
            }
            this.provVal = val
        },
        // 获取用户选择的省份城市数据
        getCityInfo(val) {
            this.cityVal = val.split('-')[1]
        },
        // 点击编辑产品信息弹窗中的确定按钮
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let info = this.form,
                        mode = info.delivery.join(','),
                        city = this.cityVal !== '请选择城市' ? this.cityVal : '',
                        prov = this.provVal !== '请选择省份' ? this.provVal : ''
                    changeProduct(info.name, city, prov, '', '', '', mode, info.model, info.firm, info.desc, this.productId).then(() => {
                        this.visible = false
                        this.getProductDetails()
                        this.$message.success('产品编辑成功！')
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 点击设置物模型
        setModel() {
            this.setModelFlag = true
            this.isShow = true
            this.isSetModel = true
            this.isSaveConfig = false
        },
        // 点击保存物模型
        onSaveList() {
            let info = JSON.parse(JSON.stringify(this.setModelData)),
                props = [],
                events = []
            info.map((item) => {
                if (item.type === 'properties') {
                    let obj = {}
                    if (item.dataType === 'int32(整数型)' || item.dataType === 'int64(长整数型)' || item.dataType === 'float(单精度浮点型)' || item.dataType === 'double(双精度浮点型)') {
                        obj = {
                            "accessMode": item.readType,
                            "dataType": {
                                "type": item.dataType.split('(')[0],
                                "specs": {
                                    "min": item.intMin,
                                    "max": item.intMax,
                                    "unit": item.unit,
                                    "step": item.length
                                }
                            }
                        }
                    } else if (item.dataType === 'enum(枚举)') {
                        let obj1 = {}
                        item.domains.map((val) => {
                            obj1[val.value] = val.desc
                        })
                        obj = {
                            "dataType": {
                                "type": "enum",
                                "specs": obj1
                            }
                        }
                    } else if (item.dataType === 'bool(布尔)') {
                        obj = {
                            "accessMode": item.readType,
                            "dataType": {
                                "type": "bool",
                                "specs": {
                                    "true": item.boolT,
                                    "false": item.boolF
                                }
                            }
                        }
                    } else if (item.dataType === 'string(字符串)') {
                        obj = {
                            "accessMode": item.readType,
                            "dataType": {
                                "type": "string",
                                "specs": {
                                    "length": item.dataLen
                                }
                            }
                        }
                    } else if (item.dataType === 'date(时间)') {
                        obj = {
                            "accessMode": item.readType,
                            "dataType": {
                                "type": "date",
                                "specs": {}
                            }
                        }
                    } else if (item.dataType === 'array(数组)') {
                        let obj1 = {}
                        if (item.arrayDataType === 'int32' || item.arrayDataType === 'int64' || item.arrayDataType === 'float' || item.arrayDataType === 'double') {
                            obj1 = {
                                "type": item.arrayDataType,
                                "specs": {
                                    "min": item.arrayIntMin,
                                    "max": item.arrayIntMax,
                                    "unit": item.unit,
                                    "step": item.length
                                }
                            }
                        } else if (item.arrayDataType === 'string') {
                            obj1 = {
                                "accessMode": item.readType,
                                "dataType": {
                                    "type": "string",
                                    "specs": {
                                        "length": item.arrayDataLen
                                    }
                                }
                            }
                        } else {
                            obj1 = {
                                "accessMode": item.readType,
                                "dataType": {
                                    "type": "date",
                                    "specs": {}
                                }
                            }
                        }
                        obj = {
                            "accessMode": item.readType,
                            "dataType": {
                                "type": "array",
                                "specs": {
                                    "length": item.len,
                                    "type": "struct",
                                    "specs": obj1
                                }
                            }
                        }
                    } else {
                        obj = {
                            "accessMode": item.readType,
                            "dataType": {
                                "type": item.dataType.split('(')[0],
                                "specs": {
                                    "length": item.dataLen
                                }
                            }
                        }
                    }
                    props.push(Object.assign(item, obj))
                } else if (item.type === 'events') {
                    let obj = {},
                        newObj = {},
                        arr = []
                    item.paramList.map((item1) => {
                        if (item1.dataType === 'int32' || item1.dataType === 'int64' || item1.dataType === 'float' || item1.dataType === 'double') {
                            obj = {
                                "identifier": item1.sign,
                                "name": item1.name,
                                "dataType": {
                                    "type": item1.dataType.split('(')[0],
                                    "specs": {
                                        "min": item1.intMin,
                                        "max": item1.intMax,
                                        "unit": item1.unit,
                                        "step": item1.length
                                    }
                                }
                            }
                        } else if (item1.dataType === 'enum') {
                            let obj1 = {}
                            item1.domains.map((val) => {
                                obj1[val.value] = val.desc
                            })
                            obj = {
                                "identifier": item1.sign,
                                "name": item1.name,
                                "dataType": {
                                    "type": "enum",
                                    "specs": obj1
                                }
                            }
                        } else if (item1.dataType === 'bool') {
                            obj = {
                                "identifier": item1.sign,
                                "name": item1.name,
                                "dataType": {
                                    "type": "bool",
                                    "specs": {
                                        "true": item1.boolT,
                                        "false": item1.boolF
                                    }
                                }
                            }
                        } else if (item1.dataType === 'string') {
                            obj = {
                                "identifier": item1.sign,
                                "name": item1.name,
                                "dataType": {
                                    "type": "string",
                                    "specs": {
                                        "length": item1.dataLen
                                    }
                                }
                            }
                        } else if (item1.dataType === 'date') {
                            obj = {
                                "identifier": item1.sign,
                                "name": item1.name,
                                "dataType": {
                                    "type": "date",
                                    "specs": {}
                                }
                            }
                        } else if (item1.dataType === 'array') {
                            let obj1 = {}
                            if (item1.arrayDataType === 'int32' || item1.arrayDataType === 'int64' || item1.arrayDataType === 'float' || item1.arrayDataType === 'double') {
                                obj1 = {
                                    "type": item1.arrayDataType,
                                    "specs": {
                                        "min": item1.arrayIntMin,
                                        "max": item1.arrayIntMax,
                                        "unit": item1.unit,
                                        "step": item1.length
                                    }
                                }
                            } else if (item1.arrayDataType === 'string') {
                                obj1 = {
                                    "dataType": {
                                        "type": "string",
                                        "specs": {
                                            "length": item1.arrayDataLen
                                        }
                                    }
                                }
                            } else {
                                obj1 = {
                                    "dataType": {
                                        "type": "date",
                                        "specs": {}
                                    }
                                }
                            }
                            obj = {
                                "identifier": item1.sign,
                                "name": item1.name,
                                "dataType": {
                                    "type": "array",
                                    "specs": {
                                        "length": item1.len,
                                        "type": "struct",
                                        "specs": obj1
                                    }
                                }
                            }
                        } else {
                            obj = {
                                "identifier": item1.sign,
                                "name": item1.name,
                                "dataType": {
                                    "type": item1.dataType.split('(')[0],
                                    "specs": {
                                        "length": item1.dataLen
                                    }
                                }
                            }
                        }
                        arr.push(obj)
                    })
                    newObj = {
                        list: arr
                    }
                    events.push(Object.assign(item, newObj))
                }
            })
            let newArray = [],
                newArray1 = []
            props.forEach((val) => {
                let newObj = {}
                newObj = {
                    "identifier": val.sign,
                    "name": val.name,
                    "functionType": "u",
                    "accessMode": val.accessMode,
                    "functionMode": val.type,
                    "desc": val.desc,
                    "dataType": val.dataType
                }
                newArray.push(newObj)
            })
            events.forEach((value) => {
                let newObj = {}
                newObj = {
                    "identifier": value.sign,
                    "name": value.name,
                    "functionType": "u",
                    "functionMode": value.type,
                    "desc": value.desc,
                    "eventType": value.eventType,
                    "outputData": value.list
                }
                newArray1.push(newObj)
            })
            let result = {
                "properties": newArray,
                "events": newArray1,
                "services": []
            }
            updateModel(result, this.productInfo.code, this.setModelData).then(() => {
                this.$message.success('物模型保存成功!')
                this.setModelFlag = false
                this.isSava = false
            }).catch(error => {
                console.log(error)
            })
        },
        // 点击添加自定义功能点弹窗中的保存按钮
        onSave(val) {
            this.setModelData.push(JSON.parse(JSON.stringify(val)))
            this.dataList = this.setModelData
            this.modelList = this.setModelData
            this.isVisible = false
            this.isSava = true
        },
        // 点击设备档案列表中的详情
        gotoDetails(val) {
            console.log(val)
            this.$router.push(`/equip-list/details?id=${val.id}`)
        },
        // 选择功能类型
        funType(val) {
            this.funcType = val
            this.onKeySearch()
        },
        // 选择功能类别
        funCata(val) {
            this.catatype = val
            // this.onKeySearch()
        },
        // 关键字搜索物模型管理
        onKeySearch() {
            let info = JSON.parse(JSON.stringify(this.modelList)),
                arr = []
            if(this.funcType === 'all') {
                this.setModelData = this.modelList
                return
            }
            info.map((item) => {
                if(item.type === this.funcType && this.keywords === '') {
                    arr.push(item)
                } else if(item.type === this.funcType && this.keywords === item.name) {
                    arr.push(item)
                }
            })
            this.setModelData = arr
        },
        // 点击物模型管理列表中的查看操作
        showInfo(val) {
            this.isShowDialog = true
            this.objInfo = val
        },
        // 点击物模型管理列表中的删除操作
        onDelete(key) {
            this.$confirm({
                title: '功能点删除',
                content: '确认删除该功能点吗',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.setModelData.splice(key, 1)
                    this.isSaveConfig = false
                }
            })
        },
        // 选择最近在线时间段
        nowDate(val) {
            // 格式化日期
            this.onlineDate = {
                start: moment(val[0]).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        // 选择最近添加时间段
        nowAddDate(val) {
            // 格式化日期
            this.addDate = {
                start: moment(val[0]).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        onlineDateClear(val) {
            if (val === 0) {
                this.onlineDate = {
                    start: '',
                    end: ''
                }
            }
        },
        nowDateClear(val) {
            if (val === 0) {
                this.addDate = {
                    start: '',
                    end: ''
                }
            }
        },
        // 重置日期选择
        resetDate() {
            this.equipStatus = 'all'
            this.createType = 'all'
            this.keyword = ''
            this.nowDates = []
            this.nowAddDates = []
            this.onlineDate = {}
            this.addDate = {}
        },
        // 通过日期选择后查询
        searchProduct() {
            this.reloadList(1, this.size)
        },
        goBack() {
            if (this.isSava || this.isSaveConfig) {
                this.$confirm({
                    title: '',
                    content: '您已编辑产品物模型功能点，是否保存当前更改？',
                    okText: '保存',
                    centered: true,
                    okType: 'danger',
                    cancelText: '放弃更改',
                    onCancel: () => {
                        this.getProductDetails()
                        this.setModelFlag = false
                        this.isSava = false
                    },
                    onOk: () => {
                        this.onSaveList()
                    }
                })
            } else {
                this.isShow = this.setModelData.length > 0
                this.setModelFlag = false
            }
            this.isSetModel = false
        }
    }
}
</script>

<style lang="scss" scoped>
    .detailsWrap {
        margin: 30px;
        a {
            color: #333;
        }
        .detailsTitle {
            font-size: 20px;
            font-weight: bold;
            margin-right: 20px;
        }
        .detailsChoose {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px
        }
        .detailsList {
            margin: 40px 40px 30px;
            display: flex;
            justify-content: space-between;
            ul li {
                margin-bottom: 12px;
            }
        }
        .card-container {
            background: #f5f5f5;
            overflow: hidden;
            padding: 24px;
        }
        .card-container > .ant-tabs-card > .ant-tabs-content {
            height: 120px;
            margin-top: -16px;
        }

        .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane > .ant-table-wrapper{
            background: #fff;
            padding: 16px;
        }

        .card-container > .ant-tabs-card > .ant-tabs-bar {
            border-color: #fff;
        }

        .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
            border-color: transparent;
            background: transparent;
        }

        .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
            border-color: #fff;
            background: #fff;
        }
        ::v-deep .ant-pagination {
            text-align: right;
        }
        ::v-deep .ant-pagination-total-text {
            float: left;
        }
    }
    .addProduct {
        ::v-deep .ant-form-item-control-wrapper {
            flex: 1;
        }
    }
    .setModel {
        color: #1890ff;
        font-size: 40px;
        cursor: pointer
    }
    ::v-deep .hiddenLabel .ant-form-item-label{
        opacity: 0;
    }
</style>
