<template>
    <div>
        <a-modal
            :width="800"
            title="查看输出参数"
            :visible="isParamShow"
            @cancel="closeDialog">
            <a-form-model
                ref="modelRuleForm"
                :model="paramModelForm">
                <a-form-model-item label="参数名称">
                    <a-input
                        v-model="paramModelForm.name"
                        disabled />
                </a-form-model-item>
                <a-form-model-item label="标识符">
                    <a-input
                        v-model="paramModelForm.sign"
                        disabled />
                </a-form-model-item>
                <a-form-model-item label="数据类型">
                    <a-select v-model="paramModelForm.dataType" disabled>
                        <a-select-option value="int32(整数型)">
                            int32(整数型)
                        </a-select-option>
                        <a-select-option value="int64(长整数型)">
                            int64(长整数型)
                        </a-select-option>
                        <a-select-option value="float(单精度浮点型)">
                            float(单精度浮点型)
                        </a-select-option>
                        <a-select-option value="double(双精度浮点型)">
                            double(双精度浮点型)
                        </a-select-option>
                        <a-select-option value="enum(枚举)">
                            enum(枚举)
                        </a-select-option>
                        <a-select-option value="bool(布尔)">
                            bool(布尔)
                        </a-select-option>
                        <a-select-option value="string(字符串)">
                            string(字符串)
                        </a-select-option>
                        <a-select-option value="date(时间)">
                            date(时间)
                        </a-select-option>
                        <a-select-option value="array(数组)">
                            array(数组)
                        </a-select-option>
                        <a-select-option value="file(文件)">
                            file(文件)
                        </a-select-option>
                        <a-select-option value="password(密码)">
                            password(密码)
                        </a-select-option>
                        <a-select-option value="geopoint(地理位置)">
                            geopoint(地理位置)
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <div v-show="paramModelForm.dataType === 'int32' || paramModelForm.dataType === 'int64' || paramModelForm.dataType === 'float' || paramModelForm.dataType === 'double'">
                    <a-form-model-item style="display: inline-block; width: 27%" label="定义取值范围">
                        <a-input
                            v-model="paramModelForm.intMin"
                            disabled />
                    </a-form-model-item>
                    <a-form-model-item class="hiddenLabel" style="display: inline-block; margin: 0 2%" label="-">
                        <span>——</span>
                    </a-form-model-item>
                    <a-form-model-item class="hiddenLabel" style="display: inline-block; width: 27%" label="定义取值范围">
                        <a-input
                            v-model="paramModelForm.intMax"
                            disabled />
                    </a-form-model-item>
                    <a-form-model-item style="display: inline-block; width: 47%" label="步长">
                        <a-input
                            v-model="paramModelForm.length"
                            disabled
                            placeholder="请输入数据精度" />
                    </a-form-model-item>
                    <a-form-model-item style="display: inline-block; width: 47%; margin-left: 6%" label="单位">
                        <a-input
                            v-model="paramModelForm.unit"
                            disabled
                            placeholder="请自定义数据单位" />
                    </a-form-model-item>
                </div>
                <div v-show="paramModelForm.dataType === 'enum'">
                    <p>
                        <span>枚举项</span>
                        <span style="margin-left: 100px; color: #999">最多100项</span>
                    </p>
                    <p>
                        <span>参数值</span>
                        <span style="margin-left: 27%">参数描述</span>
                    </p>
                    <a-form-model-item
                        v-for="(domain, index) in paramModelForm.domains"
                        :key="domain.key"
                        :prop="'domains.' + index + '.value'">
                        <a-input
                            v-model="domain.value"
                            disabled
                            style="width: 30%; display: inline-block" />
                        <a-input
                            v-model="domain.desc"
                            disabled
                            placeholder="请输入参数描述"
                            style="width: 55%; display: inline-block; margin: 0 10px 0 2%" />
                    </a-form-model-item>
                </div>
                <div v-show="paramModelForm.dataType === 'bool'">
                    <a-form-model-item label="布尔值">
                        true - <a-input
                            v-model="paramModelForm.boolT"
                            disabled
                            style="display: inline-block; width: 80%; margin-left: 12px" />
                    </a-form-model-item>
                    <a-form-model-item label="">
                        false - <a-input
                            v-model="paramModelForm.boolF"
                            disabled
                            style="display: inline-block; width: 80%; margin-left: 12px" />
                    </a-form-model-item>
                </div>
                <div v-show="paramModelForm.dataType === 'string'">
                    <a-form-model-item label="数据长度">
                        <a-input
                            v-model="paramModelForm.dataLen"
                            disabled
                            style="display: inline-block; width: 80%; margin-right: 12px" /> 字符
                    </a-form-model-item>
                </div>
                <div v-show="paramModelForm.dataType === 'date'">
                    <a-form-model-item label="时间格式">
                        <a-input
                            disabled
                            placeholder="整数类型Int64的UTC时间戳(毫秒)" />
                    </a-form-model-item>
                </div>
                <div v-show="paramModelForm.dataType === 'array'">
                    <a-form-model-item label="元素类型">
                        <a-select v-model="paramModelForm.arrayDataType" disabled>
                            <a-select-option value="int32">
                                int32(整数型)
                            </a-select-option>
                            <a-select-option value="int64">
                                int64(长整数型)
                            </a-select-option>
                            <a-select-option value="float">
                                float(单精度浮点型)
                            </a-select-option>
                            <a-select-option value="double">
                                double(双精度浮点型)
                            </a-select-option>
                            <a-select-option value="string">
                                string(字符串)
                            </a-select-option>
                            <a-select-option value="date">
                                date(时间)
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="元素个数">
                        <a-input
                            v-model="paramModelForm.len"
                            disabled />
                    </a-form-model-item>
                    <div v-show="paramModelForm.arrayDataType === 'int32' || paramModelForm.arrayDataType === 'int64' || paramModelForm.arrayDataType === 'float' || paramModelForm.arrayDataType === 'double'">
                        <a-form-model-item ref="arrayIntMin" style="display: inline-block; width: 27%" label="定义取值范围" prop="arrayIntMin">
                            <a-input
                                v-model="paramModelForm.arrayIntMin"
                                disabled />
                        </a-form-model-item>
                        <a-form-model-item class="hiddenLabel" style="display: inline-block; margin: 0 2%" label="-">
                            <span>——</span>
                        </a-form-model-item>
                        <a-form-model-item ref="arrayIntMax" class="hiddenLabel" style="display: inline-block; width: 27%" label="定义取值范围" prop="arrayIntMax">
                            <a-input
                                v-model="paramModelForm.arrayIntMax"
                                disabled />
                        </a-form-model-item>
                        <a-form-model-item ref="length" label="步长" prop="length">
                            <a-input
                                v-model="paramModelForm.length"
                                disabled
                                placeholder="请输入数据精度" />
                        </a-form-model-item>
                        <a-form-model-item ref="unit" label="单位" prop="unit">
                            <a-input
                                v-model="paramModelForm.unit"
                                disabled
                                placeholder="请自定义数据单位" />
                        </a-form-model-item>
                    </div>
                    <div v-show="paramModelForm.arrayDataType === 'string'">
                        <a-form-model-item label="数据长度">
                            <a-input
                                v-model="paramModelForm.arrayDataLen"
                                disabled
                                style="display: inline-block; width: 80%; margin-right: 12px" /> 字符
                        </a-form-model-item>
                    </div>
                    <div v-show="paramModelForm.arrayDataType === 'date'">
                        <a-form-model-item label="时间格式">
                            <a-input
                                disabled
                                placeholder="整数类型Int64的UTC时间戳(毫秒)" />
                        </a-form-model-item>
                    </div>
                </div>
            </a-form-model>
            <template slot="footer">
                <a-button key="close" @click="closeDialog">
                    关闭
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    name: "ParamDialog",
    props: {
        isParamShow: {
            type: Boolean,
            default: false
        },
        paramModelForm: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        // 关闭弹窗
        closeDialog() {
            this.$emit('closeDialog')
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .hiddenLabel .ant-form-item-label{
    opacity: 0;
}
.paramWrap {
    display: flex;
    padding: 10px 16px;
    margin-bottom: 12px;
    justify-content: space-between;
    background: #f0f4f8;
}
</style>
