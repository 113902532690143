<template>
    <div>
        <a-modal
            :width="800"
            title="查看"
            :visible="isDialogShow"
            @cancel="closeDialog">
            <a-form-model
                ref="modelRuleForm"
                :model="modelForm">
                <a-form-model-item ref="name" label="功能名称" prop="name">
                    <a-input
                        v-model="modelForm.name"
                        disabled />
                </a-form-model-item>
                <a-form-model-item ref="sign" label="标识符" prop="sign">
                    <a-input
                        v-model="modelForm.sign"
                        disabled />
                </a-form-model-item>
                <a-form-model-item ref="type" label="功能类型" prop="type">
                    <a-select v-model="modelForm.type" disabled>
                        <a-select-option value="properties">
                            属性类型
                        </a-select-option>
                        <a-select-option value="events">
                            事件类型
                        </a-select-option>
                        <a-select-option value="services" disabled>
                            服务类型
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item v-show="modelForm.type !== 'events'" ref="dataType" label="数据类型" prop="dataType">
                    <a-select v-model="modelForm.dataType" disabled>
                        <a-select-option value="int32(整数型)">
                            int32(整数型)
                        </a-select-option>
                        <a-select-option value="int64(长整数型)">
                            int64(长整数型)
                        </a-select-option>
                        <a-select-option value="float(单精度浮点型)">
                            float(单精度浮点型)
                        </a-select-option>
                        <a-select-option value="double(双精度浮点型)">
                            double(双精度浮点型)
                        </a-select-option>
                        <a-select-option value="enum(枚举)">
                            enum(枚举)
                        </a-select-option>
                        <a-select-option value="bool(布尔)">
                            bool(布尔)
                        </a-select-option>
                        <a-select-option value="string(字符串)">
                            string(字符串)
                        </a-select-option>
                        <a-select-option value="date(时间)">
                            date(时间)
                        </a-select-option>
                        <a-select-option value="array(数组)">
                            array(数组)
                        </a-select-option>
                        <a-select-option value="file(文件)">
                            file(文件)
                        </a-select-option>
                        <a-select-option value="password(密码)">
                            password(密码)
                        </a-select-option>
                        <a-select-option value="geopoint(地理位置)">
                            geopoint(地理位置)
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <!-- <a-form-model-item v-show="modelForm.type === 'events'" ref="eventType" label="事件类型" prop="eventType">
                    <a-select v-model="modelForm.eventType" disabled>
                        <a-select-option value="info">
                            信息
                        </a-select-option>
                        <a-select-option value="alert">
                            告警
                        </a-select-option>
                        <a-select-option value="error">
                            故障
                        </a-select-option>
                    </a-select>
                </a-form-model-item> -->
                <div v-show="modelForm.type !== 'events'">
                    <div v-show="modelForm.dataType === 'int32(整数型)' || modelForm.dataType === 'int64(长整数型)' || modelForm.dataType === 'float(单精度浮点型)' || modelForm.dataType === 'double(双精度浮点型)'">
                        <a-form-model-item ref="intMin" style="display: inline-block; width: 27%" label="定义取值范围" prop="intMin">
                            <a-input
                                v-model="modelForm.intMin"
                                disabled />
                        </a-form-model-item>
                        <a-form-model-item class="hiddenLabel" style="display: inline-block; margin: 0 2%" label="-">
                            <span>——</span>
                        </a-form-model-item>
                        <a-form-model-item ref="intMax" class="hiddenLabel" style="display: inline-block; width: 27%" label="定义取值范围" prop="intMax">
                            <a-input
                                v-model="modelForm.intMax"
                                disabled />
                        </a-form-model-item>
                        <a-form-model-item ref="length" style="display: inline-block; width: 47%" label="步长" prop="length">
                            <a-input
                                v-model="modelForm.length"
                                disabled
                                placeholder="请输入数据精度" />
                        </a-form-model-item>
                        <a-form-model-item ref="unit" style="display: inline-block; width: 47%; margin-left: 6%" label="单位" prop="unit">
                            <a-input
                                v-model="modelForm.unit"
                                disabled
                                placeholder="请自定义数据单位" />
                        </a-form-model-item>
                        <a-form-model-item ref="readType" label="读写类型" prop="readType">
                            <a-select v-model="modelForm.readType" disabled>
                                <a-select-option value="rw">
                                    读写
                                </a-select-option>
                                <a-select-option value="r">
                                    只读
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div>
                    <div v-show="modelForm.dataType === 'enum(枚举)'">
                        <p>
                            <span>枚举项</span>
                            <span style="margin-left: 100px; color: #999">最多100项</span>
                        </p>
                        <p>
                            <span>参数值</span>
                            <span style="margin-left: 27%">参数描述</span>
                        </p>
                        <a-form-model-item
                            v-for="(domain, index) in modelForm.domains"
                            :key="domain.key"
                            :prop="'domains.' + index + '.value'">
                            <a-input
                                v-model="domain.value"
                                disabled
                                style="width: 30%; display: inline-block" />
                            <a-input
                                v-model="domain.desc"
                                disabled
                                placeholder="请输入参数描述"
                                style="width: 55%; display: inline-block; margin: 0 10px 0 2%" />
                        </a-form-model-item>
                    </div>
                    <div v-show="modelForm.dataType === 'bool(布尔)'">
                        <a-form-model-item ref="boolT" label="布尔值" prop="boolT">
                            true - <a-input
                                v-model="modelForm.boolT"
                                disabled
                                style="display: inline-block; width: 80%; margin-left: 12px" />
                        </a-form-model-item>
                        <a-form-model-item ref="boolF" label="" prop="boolF">
                            false - <a-input
                                v-model="modelForm.boolF"
                                disabled
                                style="display: inline-block; width: 80%; margin-left: 12px" />
                        </a-form-model-item>
                        <a-form-model-item ref="readType" label="读写类型" prop="readType">
                            <a-select v-model="modelForm.readType" disabled>
                                <a-select-option value="rw">
                                    读写
                                </a-select-option>
                                <a-select-option value="r">
                                    只读
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div>
                    <div v-show="modelForm.dataType === 'string(字符串)'">
                        <a-form-model-item ref="dataLen" label="数据长度" prop="dataLen">
                            <a-input
                                v-model="modelForm.dataLen"
                                disabled
                                style="display: inline-block; width: 80%; margin-right: 12px" /> 字符
                        </a-form-model-item>
                        <a-form-model-item ref="readType" label="读写类型" prop="readType">
                            <a-select v-model="modelForm.readType" disabled>
                                <a-select-option value="rw">
                                    读写
                                </a-select-option>
                                <a-select-option value="r">
                                    只读
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div>
                    <div v-show="modelForm.dataType === 'date(时间)'">
                        <a-form-model-item label="时间格式">
                            <a-input
                                disabled
                                placeholder="整数类型Int64的UTC时间戳(毫秒)" />
                        </a-form-model-item>
                        <a-form-model-item ref="readType" label="读写类型" prop="readType">
                            <a-select v-model="modelForm.readType" disabled>
                                <a-select-option value="rw">
                                    读写
                                </a-select-option>
                                <a-select-option value="r">
                                    只读
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div>
                    <div v-show="modelForm.dataType === 'array(数组)'">
                        <a-form-model-item ref="arrayDataType" label="元素类型" prop="arrayDataType">
                            <a-select v-model="modelForm.arrayDataType" disabled>
                                <a-select-option value="int32">
                                    int32(整数型)
                                </a-select-option>
                                <a-select-option value="int64">
                                    int64(长整数型)
                                </a-select-option>
                                <a-select-option value="float">
                                    float(单精度浮点型)
                                </a-select-option>
                                <a-select-option value="double">
                                    double(双精度浮点型)
                                </a-select-option>
                                <a-select-option value="string">
                                    string(字符串)
                                </a-select-option>
                                <a-select-option value="date">
                                    date(时间)
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item ref="len" label="元素个数" prop="len">
                            <a-input
                                v-model="modelForm.len"
                                disabled />
                        </a-form-model-item>
                        <div v-show="modelForm.arrayDataType === 'int32' || modelForm.arrayDataType === 'int64' || modelForm.arrayDataType === 'float' || modelForm.arrayDataType === 'double'">
                            <a-form-model-item ref="arrayIntMin" style="display: inline-block; width: 27%" label="定义取值范围" prop="arrayIntMin">
                                <a-input
                                    v-model="modelForm.arrayIntMin"
                                    disabled />
                            </a-form-model-item>
                            <a-form-model-item class="hiddenLabel" style="display: inline-block; margin: 0 2%" label="-">
                                <span>——</span>
                            </a-form-model-item>
                            <a-form-model-item ref="arrayIntMax" class="hiddenLabel" style="display: inline-block; width: 27%" label="定义取值范围" prop="arrayIntMax">
                                <a-input
                                    v-model="modelForm.arrayIntMax"
                                    disabled />
                            </a-form-model-item>
                            <a-form-model-item ref="length" label="步长" prop="length">
                                <a-input
                                    v-model="modelForm.length"
                                    disabled
                                    placeholder="请输入数据精度" />
                            </a-form-model-item>
                            <a-form-model-item ref="unit" label="单位" prop="unit">
                                <a-input
                                    v-model="modelForm.unit"
                                    disabled
                                    placeholder="请自定义数据单位" />
                            </a-form-model-item>
                        </div>
                        <div v-show="modelForm.arrayDataType === 'string'">
                            <a-form-model-item ref="arrayDataLen" label="数据长度" prop="arrayDataLen">
                                <a-input
                                    v-model="modelForm.arrayDataLen"
                                    disabled
                                    style="display: inline-block; width: 80%; margin-right: 12px" /> 字符
                            </a-form-model-item>
                        </div>
                        <div v-show="modelForm.arrayDataType === 'date'">
                            <a-form-model-item label="时间格式">
                                <a-input
                                    disabled
                                    placeholder="整数类型Int64的UTC时间戳(毫秒)" />
                            </a-form-model-item>
                        </div>
                        <a-form-model-item ref="readType" label="读写类型" prop="readType">
                            <a-select v-model="modelForm.readType" disabled>
                                <a-select-option value="rw">
                                    读写
                                </a-select-option>
                                <a-select-option value="r">
                                    只读
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div>
                </div>
                <div v-show="modelForm.type === 'events'">
                    <p>
                        <span>输出参数</span>
                        <span style="margin-left: 50px; color: #999">最多支持50个参数</span>
                    </p>
                    <ul style="margin-top: 16px">
                        <li v-for="(item, index) in modelForm.paramList" :key="index" class="paramWrap">
                            <span>{{ item.name }}</span>
                            <span>{{ item.sign }}</span>
                            <span>{{ item.dataType }}</span>
                            <span style="cursor: pointer; color: #1890ff" @click="showParam(item)">查看</span>
                        </li>
                    </ul>
                </div>
                <a-form-model-item label="描述" prop="desc">
                    <a-textarea
                        v-model="modelForm.desc"
                        disabled
                        placeholder="请输入描述内容" />
                </a-form-model-item>
            </a-form-model>
            <template slot="footer">
                <a-button key="close" @click="closeDialog">
                    关闭
                </a-button>
            </template>
        </a-modal>
        <ParamDialog :is-param-show="isParamShow" :param-model-form="list" @closeDialog="isParamShow = false" />
    </div>
</template>

<script>
import ParamDialog from "@/views/dashboard/equipmentManagement/productList/components/ParamDialog"
export default {
    name: "DetailsDialog",
    components: {
        ParamDialog
    },
    props: {
        isDialogShow: {
            type: Boolean,
            default: false
        },
        modelForm: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            isParamShow: false,
            list: {}
        }
    },
    methods: {
        // 关闭弹窗
        closeDialog() {
            this.$emit('closeDialog')
        },
        // 点击弹窗中输出参数列表中的查看按钮
        showParam(val) {
            this.isParamShow = true
            this.list = val
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .hiddenLabel .ant-form-item-label{
    opacity: 0;
}
.paramWrap {
    display: flex;
    padding: 10px 16px;
    margin-bottom: 12px;
    justify-content: space-between;
    background: #f0f4f8;
}
</style>
